<template>
    <div class="dropdown">
        <button class="btn btn-sm btn-secondary dropdown-toggle mb-3" type="button" data-toggle="dropdown"
            aria-expanded="false">
            <i class="fas fa-plus"></i> Ajouter une ressource
        </button>
        <div class="dropdown-menu">
            <a class="dropdown-item" v-if="getPermission('creer-modeles') === true" href="#" @click.prevent="openCreateModeleModal()">Modeles</a>
            <a class="dropdown-item" v-if="getPermission('creer-proprietaires') === true" href="#" @click.prevent="openCreateProprietaireModal()">Proprietaires</a>
            <a class="dropdown-item" v-if="getPermission('creer-types_camions') === true" href="#" @click.prevent="openCreateTypeCamionModal()">Types de camion</a>
            <a class="dropdown-item" v-if="getPermission('creer-types_documents') === true" href="#" @click.prevent="openCreateTypeDocumentCamionModal()">Types de document de camion</a>
            <a class="dropdown-item" v-if="getPermission('creer-types_documents') === true" href="#" @click.prevent="openCreateTypeDocumentEmployeModal()">Types de document des employés</a>
            <a class="dropdown-item" v-if="getPermission('creer-trajets') === true" href="#" @click.prevent="openCreateTrajetModal()">Trajest</a>
            <a class="dropdown-item" v-if="getPermission('creer-camions') === true" href="#" @click.prevent="openCreateCamionModal()">Camions</a>
            <a class="dropdown-item" v-if="getPermission('creer-clients') === true" href="#" @click.prevent="openCreateClientModal()">Clients</a>
            <a class="dropdown-item" v-if="getPermission('creer-villes') === true" href="#" @click.prevent="openCreateVilleModal()">Villes</a>
            <a class="dropdown-item" v-if="getPermission('creer-employes') === true" href="#" @click.prevent="openCreateEmployeModal()">Employes</a>
            <a class="dropdown-item" v-if="getPermission('creer-fournisseurs') === true" href="#" @click.prevent="openCreateFournisseurModal()">Fournisseurs</a>
            <a class="dropdown-item" v-if="getPermission('creer-articles') === true" href="#" @click.prevent="openCreateArticleModal()">Articles</a>


            <a class="dropdown-item" v-if="getPermission('modifier-articles')" href="#" @click.prevent="openCreateAjustStockPieceModal()">Ajustement stock articles</a>
            <a class="dropdown-item" v-if="getPermission('creer-garages') === true" href="#" @click.prevent="openCreateGarageModal()">Garages</a>
            <a class="dropdown-item" v-if="getPermission('creer-services') === true" href="#" @click.prevent="openCreateServiceModal()">Services</a>
            
        </div>
    </div>

    
</template>

<script>
import { defineComponent, defineAsyncComponent } from 'vue';
const CreateModele = defineAsyncComponent(() => import('@/views/parc-auto/camions/modeles/CreateModele.vue'));
const CreateTypeCamion = defineAsyncComponent(() => import('@/views/parc-auto/camions/types_camions/CreateTypeCamion.vue'));
const CreateTypeDocumentCamion = defineAsyncComponent(() => import('@/views/parc-auto/camions/types_documents_camions/CreateType.vue'));
const CreateTypeDocumentEmploye = defineAsyncComponent(() => import('@/views/ressources-humaines/employes/types_document_employes/CreateType.vue'));
const CreateProprietaire = defineAsyncComponent(() => import('@/views/parc-auto/proprietaires/CreateProprietaire.vue'));
const CreateTrajet = defineAsyncComponent(() => import('@/views/operations/courses/trajets/CreateTrajet.vue'));
const CreateCamion = defineAsyncComponent(() => import('@/views/parc-auto/camions/CreateCamion.vue'));
const CreateClient = defineAsyncComponent(() => import('@/views/operations/clients/CreateClient.vue'));
const CreateVille = defineAsyncComponent(() => import('@/views/ressources/villes/CreateVille.vue'));
const CreateEmploye = defineAsyncComponent(() => import('@/views/ressources-humaines/employes/CreateEmploye.vue'));
const CreateFournisseur = defineAsyncComponent(() => import('@/views/parc-auto/interventions/fournisseurs/CreateFournisseur.vue'));
const CreateArticle = defineAsyncComponent(() => import('@/views/parc-auto/camions/articles/CreateArticle.vue'));
const CreateGarage = defineAsyncComponent(() => import('@/views/parc-auto/interventions/garages/CreateGarage.vue'));
const AjustStockPiece = defineAsyncComponent(() => import('@/views/parc-auto/camions/articles/AjustStockArticle.vue'));
const CreateService = defineAsyncComponent(() => import('@/views/parc-auto/interventions/services/CreateService.vue'));

export default defineComponent({
    methods: {
        getPermission(permission) {
            return this.$store.getters.permissions.includes(permission)
        },
        openCreateAjustStockPieceModal () {
      this.$dialog.open(AjustStockPiece, {
        props: {
          header: "Ajustement du stock",
          style: {
            width: '80vw'
          },
          modal: true
        }
      });
    },
        openCreateServiceModal() {
      this.$dialog.open(CreateService, {
        props: {
          header: "Nouveau service",
          style: {
            width: '30vw'
          },
          modal: true
        }
      });
    },
        openCreateGarageModal() {
            this.$dialog.open(CreateGarage, {
                props: {
                header: "Nouveau garage",
                style: {
                    width: '50vw'
                },
                modal: true
                }
            });
        },
        openCreateArticleModal(){
            this.$dialog.open(CreateArticle, {
                props: {
                    header: "Nouvel article",
                    style: {
                        width: '50vw'
                    },
                    modal: true
                }
            });
        },
        openCreateFournisseurModal(){
            this.$dialog.open(CreateFournisseur, {
                props: {
                    header: "Nouveau fournisseur",
                    style: {
                        width: '50vw'
                    },
                    modal: true
                }
            });
        },
        openCreateEmployeModal(){
            this.$dialog.open(CreateEmploye, {
                props: {
                    header: "Nouvel employé",
                    style: {
                        width: '50vw'
                    },
                    modal: true
                }
            });
        },
        openCreateVilleModal() {
            this.$dialog.open(CreateVille, {
                props: {
                    header: "Nouvelle ville",
                    style: {
                        width: '30vw'
                    },
                    modal: true
                }
            });
        },
        openCreateModeleModal() {
            this.$dialog.open(CreateModele, {
                props: {
                    header: "Nouveau modele",
                    style: {
                        width: '30vw'
                    },
                    modal: true
                }
            });
        },
        openCreateTypeCamionModal() {
            this.$dialog.open(CreateTypeCamion, {
                props: {
                    header: "Nouveau type de camion",
                    style: {
                        width: '30vw'
                    },
                    modal: true
                }
            });
        },
        openCreateTypeDocumentCamionModal() {
            this.$dialog.open(CreateTypeDocumentCamion, {
                props: {
                    header: "Nouveau type de document",
                    style: {
                        width: '30vw'
                    },
                    modal: true
                }
            });
        },
        openCreateTypeDocumentEmployeModal() {
            this.$dialog.open(CreateTypeDocumentEmploye, {
                props: {
                    header: "Nouveau type de document",
                    style: {
                        width: '30vw'
                    },
                    modal: true
                }
            });
        },
        openCreateProprietaireModal() {
            this.$dialog.open(CreateProprietaire, {
                props: {
                    header: "Nouveau proprietaire",
                    style: {
                        width: '50vw'
                    },
                    modal: true
                },
            });
        },
        openCreateTrajetModal() {
            this.$dialog.open(CreateTrajet, {
                props: {
                    header: "Nouveau trajet",
                    style: {
                        width: '50vw'
                    },
                    modal: true
                }
            });
        },
        openCreateClientModal() {
            this.$dialog.open(CreateClient, {
                props: {
                    header: "Nouveau client",
                    style: {
                        width: '50vw'
                    },
                    modal: true
                }
            });
        },
        openCreateCamionModal() {
            this.$dialog.open(CreateCamion, {
                props: {
                    header: "Nouveau camion",
                    style: {
                        width: '50vw'
                    },
                    modal: true
                }
            });
        },
    },
})
</script>