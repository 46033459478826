<template>
    <form @submit.prevent="createLine()" class="row d-flex align-items-center">
        <div class="col-10">
            <textarea class="form-control" rows="3" v-model="form.content" placeholder="Ecrivez votre message ici..."></textarea>
        </div>
        <div class="col-2">
            <button type="submit" class="btn btn-primary btn-block" :disabled="isLoading">
                <i class="fa-solid fa-paper-plane"></i> Envoyer
                <span v-if="isLoading == true" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </button>
        </div>
    </form>
</template>
<script>

export default {
    name: 'CreateChatMessage',
    props: ['chat_id'],
    data() {
        return {
            isLoading: false,
            table: 'chat_messages',
            form: {
                user_id: this.$store.state.user.id,
                content: '',
                chat_id: this.chat_id,
            }
        }
    },

    methods: {
        createLine() {
            this.isLoading = true
            this.axios.post('/api/chat_messages', this.form).then((response) => {
                this.isLoading = false
                if (response.data.success === true) {
                    this.$emit('refresh-messages')
                    this.$toast.add({
                        severity: 'success',
                        detail: response.data.message,
                        life: 3000
                    });
                    this.resetForm()
                }
                else {
                    response.data.errors.forEach(element => {
                        this.$toast.add({
                            severity: 'warn',
                            summary: 'Oups !',
                            detail: element,
                            life: 7000
                        });
                    });
                }
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        resetForm() {
            this.form = {
                user_id: this.$store.state.user.id,
                content: '',
                chat_id: this.chat_id,
            }
        }
    }
}
</script>