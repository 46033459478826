<template>
  <!-- <div class="card card-body p-2">
    <form @submit.prevent="generateUrl()">
      <p>Periode</p>
      <div class="form-group">
        <label>Date de chargement</label>
        <input type="date" v-model="form.debut" class="form-control form-control-sm" />
      </div>
      <div class="form-group">
        <label>Date de déchargement</label>
        <input type="date" v-model="form.fin" class="form-control form-control-sm" />
      </div>
      <hr>
      <div class="row" v-for="(selection, index) in form.conditions" :key="index">
        <div class="col-2">
          <button class="btn btn-sm btn-danger" @click.prevent="removeLine(index)">
            <i class="fa-solid fa-trash-alt "></i>
          </button>
        </div>
        <div class="col-10">
          <div class="form-group">
            <select class="form-control" v-model="selection.colonne" @change="onSelectKey(selection)" required>
              <option v-for="(column, indexCol) in this.columns" :key="indexCol" :value="column.key">
                {{ column.label }}
              </option>
            </select>
          </div>

          <div class="form-group">
            <input v-if="selection.format == 'date'" type="date" class="form-control" v-model="selection.valeur"
              :disabled="selection.colonne == null">
            <input v-else-if="selection.format == 'money'" type="number" min="0" class="form-control"
              v-model="selection.valeur" :disabled="selection.colonne == null">
            <select v-else-if="selection.format == 'state'" class="form-control" v-model="selection.valeur" required>
              <option v-for="state in this.states" :key="state" :value="state">
                {{ state }}
              </option>
            </select>
            <input v-else type="text" :placeholder="selection.label" class="form-control" v-model="selection.valeur"
              :disabled="selection.colonne == null">
          </div>
        </div>
      </div>

      <div class="my-2">
        <a href="#" class="btn text-primary shadow-sm" @click.prevent="addLine()">+ Ajouter une condition</a>
      </div>

      <button type="submit" class="btn btn-primary btn-sm btn-block" :disabled="isLoading">
        <i class="fa-solid fa-refresh"></i> Appliquer
        <span v-if="isLoading == true" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      </button>

    </form>
  </div> -->

  <div class="card card-body p-2">
    <ProgressBar v-if="isLoading === true" mode="indeterminate" style="height: 6px"></ProgressBar>
    <form @submit.prevent="generateUrl()" class="">
      <p>Periode</p>
      <div class="form-group">
        <label>Date de chargement</label>
        <input type="date" v-model="form.debut" class="form-control form-control-sm" required/>
      </div>
      <div class="form-group">
        <label>Date de déchargement</label>
        <input type="date" v-model="form.fin" class="form-control form-control-sm" required/>
      </div>

      <div class="form-group">
        <label for="proprietaire_id">Proprietaire</label>
        <select class="form-control form-control-sm" id="proprietaire_id" v-model="form.proprietaire_id">
          <option v-for="proprietaire in proprietaires" :key="proprietaire.id" :value="proprietaire.id">{{ proprietaire.name }}</option>
        </select>
      </div>

      <div class="form-group">
        <label for="camion_id">Camion</label>
        <select class="form-control form-control-sm" id="camion_id" v-model="form.camion_id">
          <option v-for="camion in camions" :key="camion.id" :value="camion.id">{{ camion.plaque }}</option>
        </select>
      </div>

      <div class="form-group">
        <label for="client_id">Client</label>
        <select class="form-control form-control-sm" id="client_id" v-model="form.client_id">
          <option v-for="client in clients" :key="client.id" :value="client.id">{{ client.name }}</option>
        </select>
      </div>

      <div class="form-group">
        <label for="chauffeur_id">Chauffeur</label>
        <select class="form-control form-control-sm" id="chauffeur_id" v-model="form.chauffeur_principal_id">
          <option v-for="chauffeur in chauffeurs" :key="chauffeur.id" :value="chauffeur.id">{{ chauffeur.name }}</option>
        </select>
      </div>

      <div class="form-group">
        <label for="statuts_reglement">Reglement</label>
        <select class="form-control form-control-sm" id="statuts_reglement" v-model="form.statut">
          <option v-for="statut in statuts_reglement" :key="statut" :value="statut">{{ statut }}</option>
        </select>
      </div>

      <div class="form-group">
        <label for="etat">Etat</label>
        <select class="form-control form-control-sm" id="etat" v-model="form.etat">
          <option v-for="etat in etats" :key="etat" :value="etat">{{ etat }}</option>
        </select>
      </div>

      <div>
        <button type="submit" class="btn btn-primary btn-sm btn-block mb-2" :disabled="isLoading">
          <i class="fa-solid fa-refresh"></i> Appliquer
          <span v-if="isLoading == true" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </button> 
        <button v-if="form != {}" type="button" class="btn btn-secondary btn-sm btn-block " :disabled="isLoading" @click="resetForm()">
          <i class="fa-solid fa-close"></i> Reinitialiser
        </button>
                 
      </div>
    </form>
  </div>
</template>

<script>
import moment from 'moment'
import { defineComponent } from 'vue';

export default defineComponent({
  name: "FilterCourse",
  props: {
    url: { type: String },
  },
  data() {
    return {
      isLoading: false,
      period: moment().format('YYYY-MM'),
      currentPeriod: moment().format('YYYY-MM'),
      key: 1,
      // totalRecords: 0,
      camions: [],
      clients: [],
      proprietaires: [],
      chauffeurs: [],
      statuts_reglement: [
        'En attente',
        'Payé',
        'Reste'
      ],
      etats: [
        'Brouillon',
        'Terminé',
        'En cours',
        'Programmé',
        'Annulé',
      ],
      form: {},
      // lastItemId: 0,
    };
  },

  mounted() {
    this.getCamions()
    this.getClients()
    this.getProprietaires()
    this.getChauffeurs()
  },
  methods: {
    // onSelectKey(selection) {
    //   let col = this.columns.filter(item => item.key == selection.colonne)[0]
    //   if (col.format) selection.format = col.format
    //   selection.label = col.label
    // },
    generateUrl() {
      this.isLoading = true

      let link = this.url

      if (link !== undefined && link.includes('?')) link = link + '&filter_by_form=1&'
      else link = link + '?filter_by_form=1&'

      if (this.form.debut != '' && this.form.fin != '') {
        link = link + '&period_from=' + this.form.debut + '&period_to=' + this.form.fin
      }

      // this.form.conditions.forEach(element => {
      //   link = link + '&' + element.colonne + '=' + element.valeur
      // });

      for (let i in this.form) {
        link = link + '&' + i + '=' + this.form[i]
      }

      this.$emit('set-filtered-url', link)
      this.isLoading = false

    },
    addLine() {
      this.lastItemId++
      this.form.conditions.push({
        id: this.lastItemId,
        type: '',
        colonne: '',
        valeur: ''
      })
    },
    removeLine(key) {
      this.form.conditions = this.form.conditions.filter((data, index) => {
        return index != key
      })
    },
    refreshUrl() {
      let link = this.url
      if (link !== undefined && link.includes('?')) link = link + '&'
      else link = link + '?'

      let like_cols = []
      let not_like_cols = []
      let equal_cols = []
      let different_cols = []
      let is_null_cols = []
      let is_not_null_cols = []

      this.form.conditions.forEach(element => {
        if (element.type == 'like') like_cols.push(element.valeur)
        else if (element.type == 'not_like') not_like_cols.push(element.valeur)
        else if (element.type == 'equal') equal_cols.push(element.valeur)
        else if (element.type == 'different') different_cols.push(element.valeur)
        else if (element.type == 'is_null') is_null_cols.push(element.valeur)
        else if (element.type == 'is_not_null') is_not_null_cols.push(element.valeur)
      });

      this.initialUrl = link + 'filter_by_form=1'
      if (like_cols.length > 0) {
        this.initialUrl = this.initialUrl + '&like_cols=' + like_cols
      }
      if (not_like_cols.length > 0) {
        this.initialUrl = this.initialUrl + '&not_like_cols=' + not_like_cols
      }
      if (equal_cols.length > 0) {
        this.initialUrl = this.initialUrl + '&equal_cols=' + equal_cols
      }
      if (different_cols.length > 0) {
        this.initialUrl = this.initialUrl + '&different_cols=' + different_cols
      }
      if (is_null_cols.length > 0) {
        this.initialUrl = this.initialUrl + '&is_null_cols=' + is_null_cols
      }
      if (is_not_null_cols.length > 0) {
        this.initialUrl = this.initialUrl + '&is_not_null_cols=' + is_not_null_cols
      }
      if (this.form.debut != '' && this.form.fin != '') {
        this.initialUrl = this.initialUrl + '&period_from=' + this.form.debut + '&period_to=' + this.form.fin
      }

      this.keyWord = ''
      this.getDatas()
      this.filterOpen = true
    },

    getCamions() {
      this.isLoading = true
      this.axios.get('/api/camions').then((response) => {
        this.camions = response.data
        this.isLoading = false
      }).catch(() => {
        this.isLoading = false
        this.$toast.add({
          severity: 'error',
          summary: 'Probleme de connexion',
          detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
          life: 5000
        });
      })
    },
    getProprietaires() {
      this.isLoading = true
      this.axios.get('/api/proprietaires').then((response) => {
        this.proprietaires = response.data
        this.isLoading = false
      }).catch(() => {
        this.isLoading = false
        this.$toast.add({
          severity: 'error',
          summary: 'Probleme de connexion',
          detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
          life: 5000
        });
      })
    },
    getChauffeurs() {
      this.isLoading = true
      this.axios.get('/api/employes?poste=Chauffeur').then((response) => {
        this.chauffeurs = response.data
        this.isLoading = false
      }).catch(() => {
        this.isLoading = false
        this.$toast.add({
          severity: 'error',
          summary: 'Probleme de connexion',
          detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
          life: 5000
        });
      })
    },
    getClients() {
      this.isLoading = true
      this.axios.get('/api/clients').then((response) => {
        this.clients = response.data
        this.isLoading = false
      }).catch(() => {
        this.isLoading = false
        this.$toast.add({
          severity: 'error',
          summary: 'Probleme de connexion',
          detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
          life: 5000
        });
      })
    },
    resetForm() {
      this.form = {}
      this.$emit('set-filtered-url', this.url)
    }

  }
})
</script>