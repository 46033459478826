<template>
    <div class="header-contentys pt-3 d-flex align-items-center">
        <div class="mr-5">
            <h4 class="text-primary font-weight-bold">Paiements ({{ lines }})</h4>
        </div>
        <div class="mr-4">
            <NavOperations></NavOperations>
        </div>
        <div class="btn-group page-nav mr-4" role="group" aria-label="Basic example">
            <a href="" class="btn " @click.prevent="changeAffichage('list')" :class="{ 'active': affichage === 'list' }">
                <i class="fa-solid fa-bars"></i>
            </a>
            <a href="" class="btn " @click.prevent="changeAffichage('cards')" :class="{ 'active': affichage === 'cards' }">
                <i class="fa-solid fa-border-all"></i>
            </a>
        </div>
    </div>
    <hr>
    <div class="body-contentys">
        <DataTableBackend
         :key="key" url="/api/paiements" :columns="columns" :affichage="affichage" :table="table" selection inCard :idParams="this.$route.params.id"
        v-on:set-total-records="updateLines">
            <template #action_group_btns=slotProps>
                <button type="button" class="btn btn-sm btn-danger mr-2" @click="deleteGroup(slotProps.data)">
                    <i class="fas fa-trash-alt"></i> Supprimer
                    <span v-if="isLoading == true" class="spinner-border spinner-border-sm" role="status"
                        aria-hidden="true"></span>
                </button>
            </template>
            <template #action_btns=slotProps>
                <button class="btn btn-sm btn-outline-dark" @click="openShowModal(slotProps.data)">
                    <i class="fa-solid fa-eye"></i>
                </button>
            </template>
        </DataTableBackend>
    </div>
</template>
  
<script >
import $ from "jquery"
import NavOperations from '../NavOperations.vue';
import { defineComponent, defineAsyncComponent } from 'vue';
const ShowPaiement = defineAsyncComponent(() => import('./ShowPaiement.vue'));
const CreatePaiement = defineAsyncComponent(() => import('./CreatePaiement.vue'));

export default defineComponent({
    name: 'IndexPaiement',
    components: { NavOperations },
    data() {
        return {
            lines: 0,
            key:1,
            table: 'paiements',
            affichage: "list",
            columns: [
                {
                    key: 'date_operation',
                    label: 'Date',
                    format: 'date',
                    exportable: true
                },
                {
                    key: 'nom_client',
                    label: 'Client',
                    exportable: true
                },
                {
                    key: 'course.trajet.libelle',
                    class: 'bg-secondary p-1 rounded text-white text-center',
                    stringWithKeys: [
                        // {
                        //     var: 'type',
                        //     text: ' de la'
                        // },
                        {
                            var: 'course.transporteur',
                        },
                        {
                            var: 'course.adresse_chargement',
                            text: '('
                        },
                        {
                            var: 'course.date_chargement',
                            text: ') -',
                            format: 'date'
                        },
                        {
                            var: 'course.adresse_dechargement',
                            text: '('
                        },
                        {
                            var: 'course.date_dechargement',
                            text: ')',
                            format: 'date'
                        }
                    ],
                    label: 'Course'
                },

                {
                    key: 'libelle',
                    label: 'Libelle',
                    exportable: true
                },
                {
                    key: 'mode',
                    label: 'Mode',
                    exportable: true
                },
                {
                    key: 'montant',
                    label: 'Montant',
                    format: 'money',
                    exportable: true
                },
                {
                    key: 'statut',
                    label: 'Statut',
                    format: 'state'
                },
            ]
        };
    },
    methods: {
        getPermission(permission) {
            return this.$store.getters.permissions.includes(permission)
        },
        changeAffichage(type) {
            this.affichage = type;
             this.key++
        },
        openShowModal(objData) {
            this.$dialog.open(ShowPaiement, {
                props: {
                    header: "Paiement ID" + objData.id,
                    style: {
                        width: '40vw'
                    },
                    modal: true
                },
                data: objData
            });
        },
        openCreateModal() {
            this.$dialog.open(CreatePaiement, {
                props: {
                    header: "Nouveau paiement",
                    style: {
                        width: '50vw'
                    },
                    modal: true
                },
                data: this.course_id
            });
        },
        deleteLine(id) {
            if (!window.confirm("Etes vous sur de vouloir supprimer ?")) {
                return
            }
            this.isLoading = true
            this.axios.post('/api/paiements/' + id + '/destroy').then(response => {
                this.isLoading = false
                if (response.data.success === true) {
                    $('#refresh' + this.table).click()
                    $('#refreshcourses').click()
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Supprimé',
                        detail: response.data.message,
                        life: 3000
                    });
                }
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        deleteGroup(selection) {
            if (!window.confirm("Etes vous sur de vouloir supprimer ?")) {
                return
            }

            this.isLoading = true
            let selectedLines = []
            for (let index = 0; index < selection.length; index++) {
                const element = selection[index];
                selectedLines.push(element.id)
            }

            this.axios.post('/api/paiements/destroy-group', { selected_lines: selectedLines }).then((response) => {
                this.isLoading = false
                if (response.data.success === true) {
                    $('#refresh' + this.table).click()
                    for (let index = 0; index < response.data.message.length; index++) {
                        const element = response.data.message[index];
                        this.$toast.add({
                            severity: element.severity,
                            detail: element.value,
                            life: parseInt(5000 * (index + 1))
                        });
                    }
                }
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        // validateGroup (selection) {
        //     if (!window.confirm("Etes vous sur de vouloir supprimer ?")) {
        //         return
        //     }

        //     this.isLoading = true
        //     let selectedLines = []
        //     for (let index = 0; index < selection.length; index++) {
        //         const element = selection[index];
        //         selectedLines.push(element.id)
        //     }

        //     this.axios.post('/api/paiements/destroy-group', { selected_lines: selectedLines }).then((response) => {
        //         this.isLoading = false
        //         if (response.data.success === true) {
        //             $('#refresh' + this.table).click()
        //             for (let index = 0; index < response.data.message.length; index++) {
        //                 const element = response.data.message[index];
        //                 this.$toast.add({
        //                     severity: element.severity,
        //                     detail: element.value,
        //                     life: parseInt(5000 * (index + 1))
        //                 });
        //             }
        //         }
        //     }).catch(() => {
        //         this.isLoading = false
        //         this.$toast.add({
        //             severity: 'error',
        //             summary: 'Probleme de connexion',
        //             detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
        //             life: 5000
        //         });
        //     })
        // },
        updateLines(nb_lines) {
            this.lines = nb_lines
        }
    },
});
</script>
    
  