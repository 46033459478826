<template>
    <button class="btn btn-sm btn-secondary mr-2" @click="exportPDF()">
        <i class="fa-solid fa-download"></i> Exporter en PDF
    </button>
    <div hidden>
        <div :id="this.table + '_export'" class="card w-100">
            <div class="card-header">
                <h5 class="card-title">Tableau des {{ this.table }}</h5>
            </div>
            <div class="card-body p-0">
                <DataTable class="p-datatable-sm" :value="this.datas" >
                    <DataTableColumn v-for="field in this.fields" :key="field" :field="field.key" :header="field.label" ></DataTableColumn>
                </DataTable>
            </div>
        </div>
    </div>
</template>
 
<script>
import html2pdf from "html2pdf.js";

export default {
    name: 'ExportTable',
    props: ['table', 'fields', 'datas'],
    methods: {
        exportPDF() {
            html2pdf().set({
                pagebreak: { mode: 'avoid-all' },
                jsPDF: { unit: 'in', format: 'a4', orientation: 'landscape' },
                margin: [0.5, 0.5],
                filename: "Liste des " + this.table,
                html2canvas: { scale: 2 }
            }).from(document.getElementById(this.table + "_export"))
                .toPdf()
                .get('pdf')
                .save();
        },
    }

}
</script>