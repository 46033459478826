<template>
    <div class="d-flex justify-content-center row">
      <div class="col-md-4">
        <ShowImage :key="key" :src="this.$store.state.user.photo" alt="Photo" imageClass="rounded w-100" preview />
        <!-- <img :src="this.$store.state.user.photo" class="rounded w-100"> -->
        <form v-on:submit.prevent="updatePhoto()">
          <div class="form-group">
            <label>Changer de photo </label>
            <input type="file" class="form-control" ref="file" v-on:change="onFilePhoto()">
          </div>
          <button type="submit" class="btn btn-success btn-sm btn-block">
            <i class="fa-solid fa-save"></i> Mettre à jour
          </button>
        </form>
      </div>
      <div class="col-md-8">
        <div class="">
          <div class="">
            <nav>
              <div class="nav nav-tabs" id="nav-tab" role="tablist">
                <button class="nav-link active" id="nav-home-tab" data-toggle="tab" :data-target="'#nav-infos'" type="button" role="tab" aria-controls="nav-home" aria-selected="true">General</button>
                <button class="nav-link" id="nav-profile-tab" data-toggle="tab" :data-target="'#nav-password'" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Mot de passe</button>
              </div>
            </nav>
            <div class="tab-content" id="nav-tabContent">
  
              <div class="tab-pane fade show active" id="nav-infos" role="tabpanel" aria-labelledby="nav-home-tab">
                  <form class="form-horizontal my-3"  method="post" v-on:submit.prevent="updateProfile()">
                    
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label">Nom d'utilisateur</label>
                      <div class="col-sm-8">
                        <input type="text" class="form-control"  v-model="formProfile.name" required :disabled="this.$store.state.user.type === 'employe'">
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label">Email</label>
                      <div class="col-sm-8">
                        <input type="email" :value="this.$store.state.user.email" class="form-control" readonly>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label">Telephone 1</label>
                      <div class="col-sm-8">
                        <input type="text" class="form-control" v-model="formProfile.telephone1" placeholder="exp: +241 077207720" required>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label">Telephone 2</label>
                      <div class="col-sm-8">
                        <input type="text" class="form-control" v-model="formProfile.telephone2" >
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label">Date de naissance</label>
                      <div class="col-sm-8">
                        <input type="date" class="form-control" v-model="formProfile.date_naissance" required>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label">Adresse</label>
                      <div class="col-sm-8">
                        <input type="text" class="form-control" v-model="formProfile.adresse" required>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label">Sexe</label>
                      <div class="col-sm-8">
                        <select class="form-control" v-model="formProfile.sexe" >
                          <option value="Masculin">Masculin</option>
                          <option value="Feminin">Feminin</option>
                        </select>
                      </div>
                    </div>
                    <button type="submit" class="btn btn-success btn-sm">
                      <i class="fa-solid fa-save"></i> Mettre à jour
                    </button>
                  </form>
              </div>
              <div class="tab-pane fade" id="nav-password" role="tabpanel" aria-labelledby="nav-profile-tab">
                  <form class="form-horizontal my-3"  v-on:submit.prevent="updatePassword()">
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label">Ancien mot de passe</label>
                      <div class="col-sm-8">
                        <input type="password" class="form-control" v-model="formPassword.current_password" required>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label">Nouveau mot de passe</label>
                      <div class="col-sm-8">
                        <input type="password" class="form-control" v-model="formPassword.new_password" required>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-4 col-form-label">Confirmation </label>
                      <div class="col-sm-8">
                        <input type="password" class="form-control" v-model="formPassword.password_confirmation" required>
                      </div>
                    </div>
                    <button type="submit" class="btn btn-success btn-sm">
                      <i class="fa-solid fa-save"></i> Mettre à jour
                    </button>
                  </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script>
  export default {
    component: {},
    data () {
      return {
        formPassword: {
          current_password: '',
          new_password: '',
          password_confirmation: ''
        },
        formPhoto: {
          photo: this.$store.state.user.photo,
        },
        formProfile: {
          name: this.$store.state.user.name,
          telephone1: this.$store.state.user.telephone1,
          telephone2: this.$store.state.user.telephone2,
          adresse: this.$store.state.user.adresse,
          date_naissance: this.$store.state.user.date_naissance,
          sexe: this.$store.state.user.sexe,
        },
        key: 1,
        isLoading: true,
        isLoadingProfile: false,
        isLoadingPassword: false
      }
    },
    methods: {
      onFilePhoto() {
        this.formPhoto.photo = this.$refs.file.files[0];
      },
      updatePhoto() {
        this.isLoading = true
        const config = {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
        this.axios.post( '/api/users/'+this.$store.state.user.id+'/update-photo', this.formPhoto, config).then(response => {
          if(response.data.success === true){
            this.$store.commit('setUserPhoto', response.data.data)
            this.$toast.success(response.data.message)
            this.key++
          }
          else {
            this.$toast.error('Une erreur s\'est produite lors de l enregistrement!')
          }
          this.isLoading = false
        }).catch(() => {
          this.isLoading = false
        })
      },
      updateProfile() {
        this.isLoadingProfile = true
        this.axios.post( '/api/users/'+this.$store.state.user.id+'/update-profile', this.formProfile).then(response => {
          if(response.data.success === true){
            this.$store.commit('setUserProfile', response.data.data)
            this.$toast.success(response.data.message)
          }
          else {
            this.$toast.error(response.data.errors)
          }
          this.isLoadingProfile = false
        }).catch(() => {
          this.isLoadingProfile = false
        })
      },
      updatePassword() {
        this.isLoadingPassword = true
        this.axios.post( '/api/users/'+this.$store.state.user.id+'/update-password', this.formPassword).then(response => {
          if (response.data.success === true) {
              this.resetFormPassword()
              this.$toast.add({
                  severity: 'success',
                  detail: response.data.message,
                  life: 3000
              });
              this.$store.commit('clearUserData')
              window.location.reload()
          }
          else {
              response.data.errors.forEach(element => {
                  this.$toast.add({
                      severity: 'warn',
                      summary: 'Oups !',
                      detail: element,
                      life: 7000
                  });
              });
          }
          this.isLoadingPassword = false
        }).catch(() => {
          this.isLoading = false
        })
      },
      resetFormPassword(){
        this.formPassword = {
          current_password: '',
          new_password: '',
          password_confirmation: ''
        }
      }
    }
  }
  </script>