<template>
    <AddResource></AddResource>
    <button id="refreshclients" @click.prevent="getClients()" hidden></button>
    <ProgressBar v-if="isLoading === true" mode="indeterminate" style="height: 6px"></ProgressBar>

    <form @submit.prevent="createLine()">
        
        <div class="form-row">
            <div class="form-group col-md-3">
                <label>Date de facturation <span class="text-danger">*</span></label>
                <input type="date" class="form-control" v-model="form.date_operation" required />
            </div>
            <div class="form-group col-md-3">
                <label>Type</label>
                <select class="form-control" v-model="form.type">
                    <option value="Normale">Normale</option>
                    <option value="Pro forma">Pro forma</option>
                </select>
            </div>
            <div class="form-group col-md-6">
                <label>Client</label><span class="text-danger">*</span>
                <SearchDropdown v-model="form.client_id" filter :options="clients" optionLabel="nom" optionValue="id" class="w-100" placeholder="Selectionner un client" />
            </div>
        </div>

        <div class="row">
            <!-- <div class="form-group col-3">
                <label>Le document est expirable ?</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <div class="input-group-text">
                            <input type="checkbox" v-model="form.expirable">
                        </div>
                    </div>

                    <input v-if="form.expirable == true" type="date" class="form-control form-control-sm" v-model="form.date_expiration" required />
                </div>
            </div> -->
            <div class="form-group col-3">
                <label>Appliquer la TVA (%)</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <div class="input-group-text">
                            <input type="checkbox" v-model="form.avec_tva" @change="applyTaxes()">
                        </div>
                    </div>
                    <input v-if="form.avec_tva == true" type="number" min="0" step="0.01" class="form-control form-control-sm" v-model="form.tva" required/>
                </div>
            </div>
            <div class="form-group col-3">
                <label>Appliquer la TPS (%)</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <div class="input-group-text">
                            <input type="checkbox" v-model="form.avec_tps" @change="applyTaxes()">
                        </div>
                    </div>
                    <input v-if="form.avec_tps == true" type="number" min="0" step="0.01"  class="form-control form-control-sm" v-model="form.tps" required />
                </div>
            </div>
            <div class="form-group col-3">
                <label>Appliquer la CSS (%)</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <div class="input-group-text">
                            <input type="checkbox" v-model="form.avec_css" @change="applyTaxes()">
                        </div>
                    </div>
                    <input v-if="form.avec_css == true" type="number" min="0" step="0.01" class="form-control form-control-sm" v-model="form.css" required />
                </div>
            </div>
        </div>

        <table class="table text-sm">
            <thead>
                <tr>
                    <th scope="col"></th>
                    <th scope="col">Description</th>
                    <th scope="col">Prix unitaire</th>
                    <th scope="col">Qté</th>
                    <th scope="col">Total</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(selection, index) in voyages_selectionnes" :key="index">
                    <td style="width: 5%;">
                        <button class="btn btn-sm btn-danger" @click.prevent="removeCourse(index)">
                            <i class="fa-solid fa-trash-alt "></i>
                        </button>
                    </td>
                    <td style="width: 45%;">
                        <input type="text" class="form-control form-control-sm" v-model="selection.description" required>
                    </td>
                    <td style="width: 20%;">
                        <input type="number" class="form-control form-control-sm" v-model="selection.prix_unitaire" required @change="onLineChange(selection)" >
                    </td>
                    <td style="width: 10%;">
                        <input type="number" min="1" class="form-control form-control-sm" v-model="selection.qte" required @change="onLineChange(selection)" >
                    </td>
                    <td style="width: 20%;">
                        <input type="number" class="form-control form-control-sm" :value="selection.net_a_payer" disabled>
                    </td>
                </tr>
            </tbody>
        </table>

        <a href="#" class="btn text-primary" @click.prevent="addCourse()">+ Ajouter une ligne</a>

        <div class="form-group col-4">
            <label>Voulez-vous appliquer une remise ?</label>
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        <input type="checkbox" v-model="form.avec_reduction">
                    </div>
                </div>
                <input v-if="form.avec_reduction == true" type="number" min="0" class="form-control form-control-sm" v-model="form.reduction" required />
            </div>
        </div>

        <ul>
            <li >
                <strong>Total HT: </strong> {{ new Intl.NumberFormat('de-DE').format(montantTotalHT) }} FCFA
            </li>
            <li v-if="form.avec_tva">
                <strong>TVA ({{ form.tva }} %) : </strong> {{ new Intl.NumberFormat('de-DE').format(getTva()) }} FCFA
            </li>
            <li v-if="form.avec_tps">
                <strong>TPS (- {{ form.tps }} %) : </strong>{{ new Intl.NumberFormat('de-DE').format(getTps()) }} FCFA
            </li>
            <li v-if="form.avec_css">
                <strong>CSS ({{ form.css }} %) : </strong>{{ new Intl.NumberFormat('de-DE').format(getCss()) }} FCFA
            </li>
            <li v-if="form.avec_reduction">
                <strong>Reduction : </strong> {{ new Intl.NumberFormat('de-DE').format(form.reduction) }} FCFA
            </li>
            <li>
                <strong>Total TTC: </strong> {{ new Intl.NumberFormat('de-DE').format(montantTTC) }} FCFA
            </li>
        </ul>

        <div class="form-group">
            <label>Conditions:</label>
            <textarea class="form-control" v-model="form.conditions" rows="2"></textarea>
        </div>

        <button type="submit" class="btn btn-primary btn-block" :disabled="isLoading" >
            <i class="fa-solid fa-save "></i> Enregistrer
        </button>
    </form>
</template>
<script>
import $ from "jquery";
import moment from 'moment'
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'CreateFacture',
    inject: ['dialogRef'],
    data() {
        return {
            isLoading: true,
            table: 'factures',
            clients: [],
            voyages_selectionnes: [
                {
                    id: 1,
                    description: '',
                    qte: 1,
                    prix_unitaire: 0,
                    net_a_payer: 0
                }
            ],
            tva: 18,
            tps: 9.5,
            css: 1,
            form: {
                client_id: '',
                expirable: false,
                date_operation: moment().format('YYYY-MM-DD'),
                date_expiration: '',
                avec_tva: false,
                avec_tps: false,
                avec_css: false,
                avec_reduction: false,
                reduction: 0,
                total_ht: 0,
                total_ttc: 0,
                voyages_selectionnes: [],
                type: 'Pro forma'
            },
            lastId: 1
        }
    },
    mounted() {
        this.getClients()
    },
    computed: {
       
        montantTotalHT () {
            let total = 0
            for (let index = 0; index < this.voyages_selectionnes.length; index++) {
                const element = this.voyages_selectionnes[index];
                if (parseFloat(element.net_a_payer)) {
                    total = total + parseFloat(element.net_a_payer)
                }
            }
            return total
        },
        montantTTC () {
            let total = this.montantTotalHT
            if (this.form.avec_tps == true && parseFloat(this.form.tps)) {
                total = total - ((this.montantTotalHT * parseFloat(this.form.tps))/100)
            }
            if (this.form.avec_css == true && parseFloat(this.form.css)) {
                total = total + ((this.montantTotalHT * parseFloat(this.form.css))/100)
            }
            if (this.form.avec_reduction == true && parseFloat(this.form.reduction)) {
                total = total - this.form.reduction
            }
            if (this.form.avec_tva == true && parseFloat(this.form.tva)) {
                total = total + ((this.montantTotalHT * parseFloat(this.form.tva))/100)
            }
            return total
        }
    },
    methods: {
        getPermission(permission) {
            return this.$store.getters.permissions.includes(permission)
        },
        applyTaxes(){
            if(this.form.avec_tva == true ) this.form.tva = this.tva
            else this.form.tva = ''
            if(this.form.avec_tps == true ) this.form.tps = this.tps
            else this.form.tps = ''
            if(this.form.avec_css == true ) this.form.css = this.css
            else this.form.css = ''
        },
        addCourse() {
            this.lastId++
            this.voyages_selectionnes.push({
                id: this.lastId,
                description: '',
                qte: 1,
                reduction: 0,
                prix_unitaire: 0,
                net_a_payer: 0
            })
        },
        removeCourse(key) {
            this.voyages_selectionnes = this.voyages_selectionnes.filter((data, index) => {
                return index != key
            })
        },
        getClients() {
            this.isLoading = true
            this.axios.get('/api/clients').then((response) => {
                this.clients = response.data
                this.isLoading = false
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        createLine() {
            this.isLoading = true
            this.form.content = this.voyages_selectionnes
            this.form.total_ht = this.montantTotalHT
            this.form.total_ttc = this.montantTTC
            this.axios.post('/api/factures', this.form)
                .then(response => {
                    this.isLoading = false
                    if (response.data.success === true) {
                        this.resetForm()
                        $('#refresh' + this.table).click()
                        this.$toast.add({
                            severity: 'success',
                            detail: response.data.message,
                            life: 3000
                        });
                        this.dialogRef.close()
                    }
                    else {
                        response.data.errors.forEach(element => {
                            this.$toast.add({
                                severity: 'warn',
                                summary: 'Oups !',
                                detail: element,
                                life: 7000
                            });
                        });
                    }
                }).catch(() => {
                    this.isLoading = false
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Probleme de connexion',
                        detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                        life: 5000
                    });
                })
        },
        onLineChange (selection) {
            let line = this.voyages_selectionnes.filter(item => item.id == selection.id)[0]
            if (parseInt(line.prix_unitaire) && parseInt(line.qte)) {
                line.net_a_payer = parseInt(line.prix_unitaire) * parseInt(line.qte)
            }
        },
        getTva () {
            let total = this.montantTotalHT
            if (total && parseFloat(this.form.tva)) {
                return (total * parseFloat(this.form.tva))/100
            }
        },
        getTps () {
            let total = this.montantTotalHT
            if (total && parseFloat(this.form.tps)) {
                return (total * parseFloat(this.form.tps))/100
            }
        },
        getCss () {
            let total = this.montantTotalHT
            if (total && parseFloat(this.form.css)) {
                return (total * parseFloat(this.form.css))/100
            }
        },
        resetForm() {
            this.voyages_selectionnes = [
                {
                    id: 1,
                    description: '',
                    qte: 1,
                    prix_unitaire: 0,
                    net_a_payer: 0
                }
            ],
            this.form = {
                client_id: '',
                expirable: false,
                date_operation: moment().format('YYYY-MM-DD'),
                date_expiration: '',
                avec_tva: false,
                avec_tps: false,
                avec_css: false,
                tva: 18,
                tps: 9.5,
                css: 1,
                avec_reduction: false,
                reduction: 0,
                total_ht: 0,
                total_ttc: 0,
                voyages_selectionnes: [],
                type: ''
            },
            this.lastId = 1
        }
    }
})
</script>