<template>
    <div class="card card-body p-2">
      <ProgressBar v-if="isLoading === true" mode="indeterminate" style="height: 6px"></ProgressBar>
      <form @submit.prevent="generateUrl()" class="">
        <p>Interval sur la date de debut</p>
        <div class="form-group">
          <label>Debut</label>
          <input type="date" v-model="form.debut" class="form-control form-control-sm" required/>
        </div>
        <div class="form-group">
          <label>Fin</label>
          <input type="date" v-model="form.fin" class="form-control form-control-sm" required/>
        </div>
  
        <div class="form-group">
          <label for="garage_id">Garage</label>
          <select class="form-control form-control-sm" id="garage_id" v-model="form.garage_id">
            <option v-for="garage in garages" :key="garage.id" :value="garage.id">{{ garage.nom }}</option>
          </select>
        </div>
  
        <div class="form-group">
          <label for="camion_id">Camion</label>
          <select class="form-control form-control-sm" id="camion_id" v-model="form.camion_id">
            <option v-for="camion in camions" :key="camion.id" :value="camion.id">{{ camion.plaque }}</option>
          </select>
        </div>
  
        <div class="form-group">
          <label for="type">Type</label>
          <select class="form-control form-control-sm" id="type" v-model="form.type">
            <option>Entretien</option>
            <option>Reparation</option>
          </select>
        </div>

  
        <!-- <div class="form-group">
          <label for="etat">Statut</label>
          <select class="form-control form-control-sm" id="etat" v-model="form.etat">
            <option v-for="etat in etats" :key="etat" :value="etat">{{ etat }}</option>
          </select>
        </div> -->
  
        <div>
          <button type="submit" class="btn btn-primary btn-sm btn-block mb-2" :disabled="isLoading">
            <i class="fa-solid fa-refresh"></i> Appliquer
            <span v-if="isLoading == true" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          </button> 
          <button v-if="form != {}" type="button" class="btn btn-secondary btn-sm btn-block " :disabled="isLoading" @click="resetForm()">
            <i class="fa-solid fa-close"></i> Reinitialiser
          </button>
                   
        </div>
      </form>
    </div>
  </template>
  
  <script>
  import moment from 'moment'
  import { defineComponent } from 'vue';
  
  export default defineComponent({
    name: "FilterCourse",
    props: {
      url: { type: String },
    },
    data() {
      return {
        isLoading: false,
        period: moment().format('YYYY-MM'),
        currentPeriod: moment().format('YYYY-MM'),
        key: 1,
        garages: [],
        etats: [
          'Brouillon',
          'Terminé',
          'En cours',
          'Programmé',
          'Annulé',
        ],
        form: {},
      };
    },
  
    mounted() {
      this.getCamions()
      this.getGarages()
    },
    methods: {

      generateUrl() {
        this.isLoading = true
  
        let link = this.url
  
        if (link !== undefined && link.includes('?')) link = link + '&filter_by_form=1&'
        else link = link + '?filter_by_form=1&'
  
        if (this.form.debut != '' && this.form.fin != '') {
          link = link + '&period_from=' + this.form.debut + '&period_to=' + this.form.fin
        }
  
        for (let i in this.form) {
          link = link + '&' + i + '=' + this.form[i]
        }
  
        this.$emit('set-filtered-url', link)
        this.isLoading = false
  
      },

      refreshUrl() {
        let link = this.url
        if (link !== undefined && link.includes('?')) link = link + '&'
        else link = link + '?'
  
        let like_cols = []
        let not_like_cols = []
        let equal_cols = []
        let different_cols = []
        let is_null_cols = []
        let is_not_null_cols = []
  
        this.form.conditions.forEach(element => {
          if (element.type == 'like') like_cols.push(element.valeur)
          else if (element.type == 'not_like') not_like_cols.push(element.valeur)
          else if (element.type == 'equal') equal_cols.push(element.valeur)
          else if (element.type == 'different') different_cols.push(element.valeur)
          else if (element.type == 'is_null') is_null_cols.push(element.valeur)
          else if (element.type == 'is_not_null') is_not_null_cols.push(element.valeur)
        });
  
        this.initialUrl = link + 'filter_by_form=1'
        if (like_cols.length > 0) {
          this.initialUrl = this.initialUrl + '&like_cols=' + like_cols
        }
        if (not_like_cols.length > 0) {
          this.initialUrl = this.initialUrl + '&not_like_cols=' + not_like_cols
        }
        if (equal_cols.length > 0) {
          this.initialUrl = this.initialUrl + '&equal_cols=' + equal_cols
        }
        if (different_cols.length > 0) {
          this.initialUrl = this.initialUrl + '&different_cols=' + different_cols
        }
        if (is_null_cols.length > 0) {
          this.initialUrl = this.initialUrl + '&is_null_cols=' + is_null_cols
        }
        if (is_not_null_cols.length > 0) {
          this.initialUrl = this.initialUrl + '&is_not_null_cols=' + is_not_null_cols
        }
        if (this.form.debut != '' && this.form.fin != '') {
          this.initialUrl = this.initialUrl + '&period_from=' + this.form.debut + '&period_to=' + this.form.fin
        }
  
        this.keyWord = ''
        this.getDatas()
        this.filterOpen = true
      },
  
      getCamions() {
        this.isLoading = true
        this.axios.get('/api/camions').then((response) => {
          this.camions = response.data
          this.isLoading = false
        }).catch(() => {
          this.isLoading = false
          this.$toast.add({
            severity: 'error',
            summary: 'Probleme de connexion',
            detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
            life: 5000
          });
        })
      },
      getGarages() {
        this.isLoading = true
        this.axios.get('/api/garages').then((response) => {
          this.garages = response.data
          this.isLoading = false
        }).catch(() => {
          this.isLoading = false
          this.$toast.add({
            severity: 'error',
            summary: 'Probleme de connexion',
            detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
            life: 5000
          });
        })
      },
      resetForm() {
        this.form = {}
        this.$emit('set-filtered-url', this.url)
      }
  
    }
  })
  </script>