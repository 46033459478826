import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from './../store'
import HomeView from '../views/HomeView.vue'
import LoginView from './../auth/LoginView.vue'
import IndexCamion from '../views/parc-auto/camions/IndexCamion.vue'
import IndexIntervention from '../views/parc-auto/interventions/IndexIntervention.vue'
import IndexAffectation from '../views/ressources-humaines/affectations/IndexAffectation.vue'
import IndexEmploye from '../views/ressources-humaines/employes/IndexEmploye.vue'
import IndexContrat from '../views/ressources-humaines/contrats/IndexContrat.vue'
import IndexConge from '../views/ressources-humaines/conges/IndexConge.vue'
import IndexCourse from '../views/operations/courses/IndexCourse.vue'
import IndexClient from '../views/operations/clients/IndexClient.vue'
import IndexPaiement from '../views/operations/paiements/IndexPaiement.vue'
import IndexFacture from '../views/comptabilite/factures/IndexFacture.vue'
import IndexAchat from '../views/comptabilite/achats/IndexAchat.vue'
import IndexJournaux from '../views/comptabilite/journaux/IndexJournaux.vue'
import IndexPieceCaisse from '../views/comptabilite/caisse/IndexCaisse.vue'
import IndexConfig from '../views/configurations/IndexConfig.vue'
import IndexTableauDeBord from '../views/tableau-de-bord/IndexTableauDeBord.vue'
import IndexProgramme from '../views/operations/programmes/IndexProgramme.vue'
import IndexCarte from '../views/geolocalisation/IndexGeoloc.vue'
import IndexProprietaire from '@/views/parc-auto/proprietaires/IndexProprietaire.vue'
import AttestationStage from '../views/ressources-humaines/employes/AttestationStage.vue'
import CourseDemande from '../views/operations/courses/demandes/IndexDemande.vue'
import IndexDemenagement from '../views/operations/courses/demenagement/IndexDemenagement.vue'
import IndexChat from '@/views/operations/chats/IndexChat.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/se-connecter',
    name: 'login',
    component: LoginView
  },
  {
    path: '/',
    name: 'accueil',
    component: HomeView
  },
  {
    path: '/parc-auto/camions',
    name: 'camions',
    component: IndexCamion
  },
  {
    path: '/parc-auto/camions/:id',
    name: 'camions.filter_by_id',
    component: IndexCamion
  },
  {
    path: '/parc-auto/interventions',
    name: 'interventions',
    component: IndexIntervention
  },
  {
    path: '/parc-auto/interventions/:id',
    name: 'interventions.filter_by_id',
    component: IndexIntervention
  },
  {
    path: '/ressources-humaines/affectations',
    name: 'affectations',
    component: IndexAffectation
  },
  {
    path: '/ressources-humaines/affectations/:id',
    name: 'affectations.filter_by_id',
    component: IndexAffectation
  },
  {
    path: '/ressources-humaines/employes',
    name: 'employes',
    component: IndexEmploye
  },
  {
    path: '/ressources-humaines/employes/:id',
    name: 'employes.filter_by_id',
    component: IndexEmploye
  },
  {
    path: '/ressources-humaines/contrats',
    name: 'contrats',
    component: IndexContrat
  },
  {
    path: '/ressources-humaines/contrats/:id',
    name: 'contrats.filter_by_id',
    component: IndexContrat
  },
  {
    path: '/ressources-humaines/conges',
    name: 'conges',
    component: IndexConge
  },
  {
    path: '/ressources-humaines/conges/:id',
    name: 'conges.filter_by_id',
    component: IndexConge
  },
  {
    path: '/operations/courses/programmes',
    name: 'courses',
    component: IndexCourse
  },
  {
    path: '/operations/courses/programmes/:id',
    name: 'courses.filter_by_id',
    component: IndexCourse
  },
  {
    path: '/operations/courses/demandes',
    name: 'courses.demandes',
    component: CourseDemande
  },
  {
    path: '/operations/courses/demandes/:id',
    name: 'courses.demandes.filter_by_id',
    component: CourseDemande
  },
  {
    path: '/operations/courses/demenagements',
    name: 'courses.demenagements',
    component: IndexDemenagement
  },
  {
    path: '/operations/courses/demenagements/:id',
    name: 'courses.demenagements.filter_by_id',
    component: IndexDemenagement
  },
  {
    path: '/operations/paiements',
    name: 'paiements',
    component: IndexPaiement
  },
  {
    path: '/operations/paiements/:id',
    name: 'paiements.filter_by_id',
    component: IndexPaiement
  },
  {
    path: '/operations/clients',
    name: 'clients',
    component: IndexClient
  },
  {
    path: '/operations/clients/:id',
    name: 'clients.filter_by_id',
    component: IndexClient
  },
  {
    path: '/comptabilite/factures',
    name: 'factures',
    component: IndexFacture
  },
  {
    path: '/comptabilite/factures/:id',
    name: 'factures.filter_by_id',
    component: IndexFacture
  },
  {
    path: '/comptabilite/achats',
    name: 'achats',
    component: IndexAchat
  },
  {
    path: '/comptabilite/achats/:id',
    name: 'achats.filter_by_id',
    component: IndexAchat
  },
  {
    path: '/comptabilite/pieces-de-caisse',
    name: 'pieces_de_caisse',
    component: IndexPieceCaisse
  },
  {
    path: '/comptabilite/pieces-de-caisse/:id',
    name: 'pieces_de_caisse.filter_by_id',
    component: IndexPieceCaisse
  },
  {
    path: '/parc-auto/proprietaires',
    name: 'proprietaires',
    component: IndexProprietaire
  },
  {
    path: '/parc-auto/proprietaires/:id',
    name: 'proprietaires.filter_by_id',
    component: IndexProprietaire
  },
  {
    path: '/operations/conversations',
    name: 'chats',
    component: IndexChat
  },
  {
    path: '/operations/conversations/:id',
    name: 'chats.filter_by_id',
    component: IndexChat
  },
  {
    path: '/tableau-de-control',
    name: 'configurations',
    component: IndexConfig
  },
  {
    path: '/tableau-de-bord',
    name: 'tableau-de-bord',
    component: IndexTableauDeBord
  },
  {
    path: '/geolocalisation',
    name: 'carte',
    component: IndexCarte
  },
  {
    path: '/ressources-humaines/employes/attestation',
    name: 'attestation.stage',
    component: AttestationStage
  },
  {
    path: '/comptabilite/journaux',
    name: 'journaux',
    component: IndexJournaux
  },
  {
    path: '/operations/calendrier',
    name: 'programmes',
    component: IndexProgramme
  },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'login' && !store.getters.authenticated) next({ name: 'login' })
  else next()
})

export default router
