<template>
    <div class="card mb-3">
        <div class="card-header">
            Nombre de camions par types 
        </div>
        <div class="card-body p-0">
            <ProgressBar v-if="isLoading === true" mode="indeterminate" style="height: 6px"></ProgressBar>
            <ChartJS type="bar" :data="TypesCamions" :options="chartOptions" class="h-30rem" />
        </div>
    </div>
</template>

<script >
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'CamionParTypes',
    data() {
        return {
            isLoading: false,
            coursesParClients: [],
            TypesCamions: {
                labels: [],
                datasets: [],
            },
            chartOptions: null
        }
    },
    mounted() {
        this.chartOptions = this.setChartOptions();
        this.getTypesCamions();
    },
    methods: {
        getTypesCamions() {
            this.isLoading = true
            this.TypesCamions = {
                labels: [],
                datasets: [],
            },
            this.axios.get('/api/types_camions').then((response) => {
                let datasets = []

                for (let index = 0; index < response.data.length; index++) {
                    const element = response.data[index];
                    this.TypesCamions.labels.push(element.libelle)
                    datasets.push(element.nb_camions)
                }

                this.TypesCamions.datasets = [{
                    type: 'bar',
                    label: 'Camions',
                    backgroundColor: '#317AC1',
                    data: datasets
                }]

                this.isLoading = false
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            });
        },
        setChartOptions() {
            const documentStyle = getComputedStyle(document.documentElement);
            const textColor = documentStyle.getPropertyValue('--text-color');
            const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
            const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

            return {
                maintainAspectRatio: false,
                aspectRatio: 0.8,
                plugins: {
                    tooltips: {
                        mode: 'index',
                        intersect: false
                    },
                    legend: {
                        labels: {
                            color: textColor
                        }
                    }
                },
                scales: {
                    x: {
                        stacked: true,
                        ticks: {
                            color: textColorSecondary
                        },
                        grid: {
                            color: surfaceBorder
                        }
                    },
                    y: {
                        stacked: true,
                        ticks: {
                            color: textColorSecondary
                        },
                        grid: {
                            color: surfaceBorder
                        }
                    }
                }
            };
        }
    },
});
</script>
  