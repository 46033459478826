<template>
    <div class="d-flex mb-4">
        <button type="button" class="btn btn-sm btn-info mr-2" @click="openEditModal(datas)"
            v-if="getPermission('modifier-factures') === true&& this.datas.est_valide != 1 ">
            <i class="fas fa-edit"></i> Modifier
        </button>

        <button v-if="getPermission('modifier-factures') === true && this.datas.est_valide != 1 " type="button"
            class="btn btn-sm btn-success mr-2" @click="validerFacture()">
            <i class="fa-solid fa-check"></i> Valider
        </button>

        <button class="btn btn-sm btn-primary mr-2" @click="download()"
            v-if="getPermission('voir-factures') === true">
            <i class="fa-solid fa-download"></i> Télécharger
        </button>
        <!-- <button class="btn btn-sm btn-primary mr-2" @click="sendFacture()"
            v-if="getPermission('telecharger-factures') === true">
            <i class="fa-solid fa-paper-plane"></i> Envoyer
        </button> -->

        <button type="button" class="btn btn-sm btn-danger mr-2" @click.prevent="deleteLine()"
            v-if="getPermission('supprimer-factures') === true&& this.datas.est_valide != 1 ">
            <i class="fas fa-trash-alt"></i> Supprimer
        </button>
    </div>
    <ProgressBar v-if="isLoading === true" mode="indeterminate" style="height: 6px"></ProgressBar>
    <div>
        <div  class="container-fluid">
            <h3 v-if="datas.type === 'Normale'" class="my-3">Facture </h3>
            <h3 v-else class="my-3">Facture proforma</h3>
            <div class="row d-flex justify-content-between mb-4">
                <div class="col-6">
                    <strong> N°0{{ datas.id }}</strong><br />
                    <strong> Date: </strong>{{ formatDate(datas.date_operation) }} <br />
                    <strong v-if="datas.date_expiration"> Echéance: </strong>{{ formatDate(datas.date_expiration) }} <br />
                </div>
                <div class="col-6">
                    <p>
                        <strong> Nom : </strong>{{ (datas.client.nom) }} <br />
                        <strong> Tel: </strong>{{ datas.client.telephone1 }} <br />
                        <strong> Email: </strong>{{ datas.client.email }} <br />
                        <strong> Adresse: </strong>{{ datas.client.adresse }} <br />
                        <strong> BP: </strong>{{ datas.client.bp }}<br />
                    </p>
                </div>
            </div>
            
            <table class="table mb-3">
                <thead class="thead-light">
                    <tr style="line-height: 1px;">
                        <th scope="col">Description</th>
                        <th scope="col">Quantité</th>
                        <th scope="col">Prix unitaire</th>
                        <th scope="col">Total HT</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="commande in commandes" :key="commande">
                        <td>{{ commande.description }}</td>
                        <td>{{ commande.qte }}</td>
                        <td>{{ formatPrice(commande.prix_unitaire) }}</td>
                        <td>{{ formatPrice(commande.net_a_payer) }}</td>
                    </tr>
                </tbody>
            </table>

            <div class="ml-auto col-6">
                <table class="table mb-3" style="line-height: 1px;">
                    <tbody>
                        <tr>
                            <td colspan="2"></td>
                            <td>Sous Total</td>
                            <td>{{ formatPriceWithDevise(datas.total_ht) }}</td>
                        </tr>
                        <tr v-if="datas.tva">
                            <td colspan="2"></td>
                            <td>TVA ({{ datas.tva }} %)</td>
                            <td>{{ formatPriceWithDevise((datas.tva / 100) * datas.total_ht) }}</td>
                        </tr>
                        <tr v-if="datas.tps">
                            <td colspan="2"></td>
                            <td>TPS (- {{ datas.tps }} %)</td>
                            <td>{{ formatPriceWithDevise((datas.tps / 100) * datas.total_ht) }}</td>
                        </tr>
                        <tr v-if="datas.css">
                            <td colspan="2"></td>
                            <td>CSS ({{ datas.css }} %)</td>
                            <td>{{ formatPriceWithDevise((datas.css / 100) * datas.total_ht) }}</td>
                        </tr>
                        <tr v-if="parseFloat(datas.reduction) > 0">
                            <td colspan="2"></td>
                            <td>Reduction </td>
                            <td>{{ formatPriceWithDevise(datas.reduction) }}</td>
                        </tr>
                        <tr>
                            <td colspan="2"></td>
                            <td>Total TTC</td>
                            <td> {{ formatPriceWithDevise(datas.total_ttc) }} </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <p v-if="datas.conditions"><strong>NB: </strong> {{ datas.conditions }}</p>
        </div>
    </div>

    <div hidden>
        <div id="facture" class="container-fluid">
            <Entete />
            <h3 v-if="datas.type === 'Normale'" class="my-3">Facture </h3>
            <h3 v-else class="my-3">Facture proforma</h3>
            <div class="row d-flex justify-content-between mb-4">
                <div class="col-6">
                    <strong> N°0{{ datas.id }}</strong><br />
                    <strong> Date: </strong>{{ formatDate(datas.date_operation) }} <br />
                    <strong v-if="datas.date_expiration"> Echéance: </strong>{{ formatDate(datas.date_expiration) }} <br />
                </div>
                <div class="col-6">
                    <p>
                        <strong> Nom : </strong>{{ (datas.client.nom) }} <br />
                        <strong> Tel: </strong>{{ datas.client.telephone1 }} <br />
                        <strong> Email: </strong>{{ datas.client.email }} <br />
                        <strong> Adresse: </strong>{{ datas.client.adresse }} <br />
                        <strong> BP: </strong>{{ datas.client.bp }}<br />
                    </p>
                </div>
            </div>
            
            <table class="table mb-3">
                <thead class="thead-light">
                    <tr style="line-height: 1px;">
                        <th scope="col">Description</th>
                        <th scope="col">Quantité</th>
                        <th scope="col">Prix unitaire</th>
                        <th scope="col">Total HT</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="commande in commandes" :key="commande">
                        <td>{{ commande.description }}</td>
                        <td>{{ commande.qte }}</td>
                        <td>{{ formatPrice(commande.prix_unitaire) }}</td>
                        <td>{{ formatPrice(commande.net_a_payer) }}</td>
                    </tr>
                </tbody>
            </table>

            <div class="ml-auto col-6">
                <table class="table mb-3" style="line-height: 1px;">
                    <tbody>
                        <tr>
                            <td colspan="2"></td>
                            <td>Sous Total</td>
                            <td>{{ formatPriceWithDevise(datas.total_ht) }}</td>
                        </tr>
                        <tr v-if="datas.tva">
                            <td colspan="2"></td>
                            <td>TVA ({{ datas.tva }} %)</td>
                            <td>{{ formatPriceWithDevise((datas.tva / 100) * datas.total_ht) }}</td>
                        </tr>
                        <tr v-if="datas.tps">
                            <td colspan="2"></td>
                            <td>TPS (- {{ datas.tps }} %)</td>
                            <td>{{ formatPriceWithDevise((datas.tps / 100) * datas.total_ht) }}</td>
                        </tr>
                        <tr v-if="datas.css">
                            <td colspan="2"></td>
                            <td>CSS ({{ datas.css }} %)</td>
                            <td>{{ formatPriceWithDevise((datas.css / 100) * datas.total_ht) }}</td>
                        </tr>
                        <tr v-if="parseFloat(datas.reduction) > 0">
                            <td colspan="2"></td>
                            <td>Reduction </td>
                            <td>{{ formatPriceWithDevise(datas.reduction) }}</td>
                        </tr>
                        <tr>
                            <td colspan="2"></td>
                            <td>Total TTC</td>
                            <td> {{ formatPriceWithDevise(datas.total_ttc) }} </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="ml-auto col-5">
                <h6>La direction</h6>
                <img src="@/assets/yams-cachet.png" class="my-5">
            </div>

            <p v-if="datas.conditions"><strong>NB: </strong> {{ datas.conditions }}</p>
        </div>
    </div>
</template>

<script>
import $ from "jquery"
import { defineComponent, defineAsyncComponent } from 'vue';
const EditFacture = defineAsyncComponent(() => import('./EditFacture.vue'));
import moment from 'moment'
import Entete from '@/layouts/EnteteDesExport.vue';
import html2pdf from "html2pdf.js";

export default defineComponent({
    name: 'ShowFacture',
    inject: ['dialogRef'],
    components: { Entete },
    data() {
        return {
            isLoading: false,
            table: 'factures',
            datas: this.dialogRef.data,
            commandes: this.dialogRef.data.commandes,
            key: 1
        }
    },

    methods: {
        getPermission(permission) {
            return this.$store.getters.permissions.includes(permission)
        },
        formatDate(val) {
            if (val) return moment(String(val)).format('DD/MM/YYYY')
        },
        formatPriceWithDevise(val) {
            if (val) return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'XAF' }).format(val)
        },
        formatPrice(val) {
            if (val) return new Intl.NumberFormat('de-DE').format(val)
        },
        download() {
            this.isLoading = true
            html2pdf().set({
                pagebreak: { mode: 'avoid-all' },
                jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
                margin: [0.5, 0.5],
                filename: "Facture-" + this.datas.id + '-' + this.datas.client.nom,
                html2canvas: { scale: 2 },
                image: { type: 'jpeg', quality: 0.98 },
            }).from(document.getElementById("facture"))
                .toPdf()
                .get('pdf')
                .then(function (pdf) {
                    var totalPages = pdf.internal.getNumberOfPages();
                    pdf.setFontSize(10);
                    pdf.setTextColor(150);
                    for (var i = 1; i <= totalPages; i++) {
                        pdf.setPage(i);
                        pdf.text(' S.A.R.L au capital de 1.000.000 F CFA | Oloumi - BP: 1073 Libreville | Tel: +241 (0) 1 74 78 91 - 062 13 71 78 ' + "\n" + 'RCCM: GA-LBV-01-2020-B13-00255 / NIF: 054889 C  |Email: infos@yamslogistics.com / Site web: yamslogistics.com', pdf.internal.pageSize.getWidth() - 0.50, pdf.internal.pageSize.getHeight() - 0.50, { align: 'right' });
                    }
                })
                .save();
            setTimeout(() => {
                this.isLoading = false
            }, 5000)
        },
        openEditModal(objData) {
            this.dialogRef.close()
            this.$dialog.open(EditFacture, {
                props: {
                    header: "Facture " + objData.id,
                    style: {
                        width: '60vw'
                    },
                    modal: true
                },
                data: objData
            });
        },
        validerFacture() {
            this.isLoading = true
            this.axios.post('/api/factures/' + this.datas.id + '/validate').then((response) => {
                this.isLoading = false
                if (response.data.success === true) {
                    $('#refresh' + this.table).click()
                    this.$toast.add({
                        severity: 'success',
                        detail: response.data.message,
                        life: 3000
                    });
                    this.dialogRef.close()
                }
                else {
                    response.data.errors.forEach(element => {
                        this.$toast.add({
                            severity: 'warn',
                            summary: 'Oups !',
                            detail: element,
                            life: 7000
                        });
                    });
                }
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        deleteLine() {
            if (!window.confirm("Etes vous sur de vouloir supprimer ?")) {
                return
            }
            this.isLoading = true
            this.axios.post('/api/factures/' + this.datas.id + '/destroy').then((response) => {
                this.isLoading = false
                if (response.data.success === true) {
                    $('#refresh' + this.table).click()
                    this.dialogRef.close()
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Supprimé',
                        detail: response.data.message,
                        life: 3000
                    });
                }
                else {
                    response.data.errors.forEach(element => {
                        this.$toast.add({
                            severity: 'warn',
                            summary: 'Oups !',
                            detail: element,
                            life: 20000
                        });
                    });
                }
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        }
    }
})
</script>