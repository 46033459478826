<template>
    <div class="header-contentys pt-3 d-flex align-items-center">
        <div class="mr-5">
            <h2 class="text-primary"> Tableau de bord </h2>
        </div>
    </div>
    <hr>
    <div class="body-contentys ">
        <div class="row mb-3">
            <div class="col-md-4">
                <camion-par-types></camion-par-types>
            </div>
            <div class="col-md-4">
                <courses-par-mois></courses-par-mois>
            </div>
            <div class="col-md-4">
                <payes-and-impayes></payes-and-impayes>
            </div>
            <div class="col-md-6">
                <courses-par-clients></courses-par-clients>
            </div>
        </div>
    </div>
</template>
  
<script >
import { defineComponent } from 'vue';
import CamionParTypes from './CamionParTypes.vue'
import CoursesParMois from './CoursesParMois.vue'
import PayesAndImpayes from './PayesAndImpayes.vue'
import CoursesParClients from './CoursesParClients.vue'

export default defineComponent({
    name: 'IndexTableauDeBord',
    components: { CamionParTypes, CoursesParMois, PayesAndImpayes, CoursesParClients },
    data() {
        return {
            isLoading: false,
        }
    },
    methods: {
        getPermission(permission) {
            return this.$store.getters.permissions.includes(permission)
        }
    },
});
</script>
  