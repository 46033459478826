<template>
    <ProgressBar v-if="isLoading === true" mode="indeterminate" style="height: 6px"></ProgressBar>
   <form @submit.prevent="createLine()">
       <div class="form-row">
           <div class="form-group col-md-6">
               <label>Date d'opération <span class="text-danger">*</span></label>
               <input type="date" class="form-control" v-model="form.date_operation" required />
           </div>
           <div class="form-group col-md-6">
               <label>Bénéficiaire <span class="text-danger">*</span></label>
               <input type="text" class="form-control" v-model="form.beneficiaire" required />
           </div>
           <div class="form-group col-md-6">
               <label>Décaisseur <span class="text-danger">*</span></label>
               <input type="text" class="form-control" v-model="form.decaisseur" required />
           </div>
           <div class="form-group col-md-6">
               <label>Montant <span class="text-danger">*</span></label>
               <input type="number" class="form-control" v-model="form.montant" required />
           </div>
       </div>
       <div class="form-group">
            <label>Libelle <span class="text-danger">*</span></label>
            <input type="text" class="form-control" v-model="form.libelle" required />
        </div>
       <div class="form-group">
            <label> Motif </label>
            <TextEditor v-model="form.motif" editorStyle="height: 100px">
                <template v-slot:toolbar>
                <span class="ql-formats">
                    <button v-tooltip.bottom="'Bold'" class="ql-bold"></button>
                    <button v-tooltip.bottom="'Italic'" class="ql-italic"></button>
                    <button v-tooltip.bottom="'Underline'" class="ql-underline"></button>
                </span>
                </template>
            </TextEditor>
        </div>
        <button type="submit" class="btn btn-primary btn-block" :disabled="isLoading">
            <i class="fa-solid fa-save "></i> Enregistrer
        </button>
   </form>
</template>
<script>
import $ from "jquery";
import moment from 'moment'
import { defineComponent } from 'vue';

export default defineComponent({
   name: 'CreateCaisse',
   inject: ['dialogRef'],
   data() {
       return {
           isLoading: true,
           table: 'piece_de_caisses',
           caisses: [],
           form: {
               date_operation: moment().format('YYYY-MM-DD')
           },
       }
   },
   mounted() {
       this.getCaisses()
   },
   methods: {
       getPermission(permission) {
           return this.$store.getters.permissions.includes(permission)
       },
       getCaisses() {
           this.isLoading = true
           this.axios.get('/api/piece_de_caisses').then((response) => {
               this.caisses = response.data
               this.isLoading = false
           }).catch(() => {
               this.isLoading = false
               this.$toast.add({
                   severity: 'error',
                   summary: 'Probleme de connexion',
                   detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                   life: 5000
               });
           })
       },
       createLine() {
           this.isLoading = true
           this.form.voyages_selectionnes = this.voyages_selectionnes
           this.axios.post('/api/piece_de_caisses', this.form)
               .then(response => {
                   this.isLoading = false
                   if (response.data.success === true) {
                       this.resetForm()
                       $('#refresh' + this.table).click()
                       this.$toast.add({
                           severity: 'success',
                           detail: response.data.message,
                           life: 3000
                       });
                       this.dialogRef.close()
                   }
                   else {
                       response.data.errors.forEach(element => {
                           this.$toast.add({
                               severity: 'warn',
                               summary: 'Oups !',
                               detail: element,
                               life: 7000
                           });
                       });
                   }
               }).catch(() => {
                   this.isLoading = false
                   this.$toast.add({
                       severity: 'error',
                       summary: 'Probleme de connexion',
                       detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                       life: 5000
                   });
               })
       },
       resetForm() {
           this.form = {
                date_operation: moment().format('YYYY-MM-DD'),
           },
           this.lastId = 1
       }
   }
})
</script>