/* eslint-disable */

import "primevue/resources/themes/bootstrap4-light-blue/theme.css"
import 'jquery/dist/jquery'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import "@lottiefiles/lottie-player"
import 'primeflex/primeflex.css';


import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import PrimeVue from 'primevue/config';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Paginator from 'primevue/paginator';
import DynamicDialog from 'primevue/dynamicdialog';
import DialogService from 'primevue/dialogservice';
import Dropdown from 'primevue/dropdown';
import ProgressSpinner from 'primevue/progressspinner';
import Image from 'primevue/image';
import FileUpload from 'primevue/fileupload';
import MonDataTable from '@/components/TopDataTable.vue';
import DataTableBackend from '@/components/DataTableBackend.vue';
import Editor from "primevue/editor";
import InputNumber from 'primevue/inputnumber';
import AutoComplete from 'primevue/autocomplete';
import ProgressBar from 'primevue/progressbar';
import DataView from 'primevue/dataview';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions';
import Toast from 'primevue/toast'; 
import ToastService from 'primevue/toastservice';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Chart from 'primevue/chart';
import SelectButton from 'primevue/selectbutton';
import SpeedDial from 'primevue/speeddial';
import MultiSelect from 'primevue/multiselect';
import ExportTable from "@/components/ExportTable.vue";
import AddResource from '@/components/AddResource.vue'


axios.defaults.withCredentials = true
axios.defaults.baseURL = store.getters.apiUrl

//si l'utilisateur s'est déjà connecté
axios.interceptors.request.use(config => {
    if(localStorage.getItem("yamsmanager_session") !== null){
        const user = JSON.parse(localStorage.getItem("yamsmanager_session") || "");
        config.headers["Authorization"] = `Bearer ${user.token}`;
        store.commit('setAuthenticated', user)
    }
    return config;
});

// redirriger si l'utilisateur n'est pas connecté
axios.interceptors.response.use((response: any)=>{
    if(response.status==401){
            store.commit('clearUserData')
            router.push({ name: 'login' })
        } else {
            return response
        }
    },
    (error)=>{
        if(error.response.status==401){
            store.commit('clearUserData')
            router.push({ name: 'login' })
        }
    },
);

const app = createApp(App);
app.use(VueAxios, axios)
app.use(PrimeVue)
app.use(store)
app.use(router)
app.use(DialogService)
app.use(ToastService)

app.component('DataTable', DataTable);
app.component('DataTableColumn', Column);
app.component('DataPaginator', Paginator);
app.component('DynamicDialog', DynamicDialog);
app.component('SearchDropdown', Dropdown);
app.component('ProgressSpinner', ProgressSpinner);
app.component('ShowImage', Image);
app.component('FileUpload', FileUpload);
app.component('MonDataTable', MonDataTable);
app.component('TextEditor', Editor);
app.component('InputNumber', InputNumber);
app.component('AutoComplete', AutoComplete);
app.component('ProgressBar', ProgressBar);
app.component('DataView', DataView);
app.component('DataViewLayoutOptions', DataViewLayoutOptions);
app.component('ShowToast', Toast);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('ChartJS', Chart);
app.component('SelectButton', SelectButton);
app.component('SpeedDial', SpeedDial);
app.component('MultiSelect', MultiSelect);
app.component('DataTableBackend', DataTableBackend);
app.component('ExportTable', ExportTable);
app.component('AddResource', AddResource);

app.mount('#app')