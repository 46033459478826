<template>
  <div class="header-contentys py-3 d-flex align-items-center">
    <div class="mr-5">
      <h4 class="text-primary font-weight-bold">Geolocalisation</h4>
    </div>
    <div>
      <button class="btn btn-outline-dark" @click="openFullscreen()">Basculer en plein écran</button>
    </div>
    </div>
    <div class="body-contentys">
      <!-- <ProgressBar v-if="isLoading === true" mode="indeterminate" style="height: 6px"></ProgressBar> -->
      <div class="row d-flex">
        <div class="col-md-3" v-if="devices.length > 0">
          <ul class="list-group list-group-flush">
            <li class="btn list-group-item" v-for="device in devices" :key="device.id" @click="flyToDevice(device)">
              
              <span v-if="current_device != null && device.id == current_device.id">
                <strong class="mr-3">{{ device.nom }}</strong>
                <i class="fa-solid fa-arrow-pointer" ></i>
              </span>
              <span v-else>{{ device.nom }}</span>
              
            </li>
          </ul>
        </div>
        <div class="col-md">
          <div class="card card-body p-0 shadow-md" id="mapCard">
            <div :key="key" ref="mapContainer" class="map rounded"></div>
          </div>
        </div>
      </div>
    
  </div>
</template>

<script>
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
// import 'leaflet-control-geocoder';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';

export default {
  name: 'CarteView',
  data() {
    return {
      key: 1,
      isLoading: true,
      map: null,
      adresse: '',
      markers: [],
      devices: [],
      current_position: [],
      syncInterval: null,
      // url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
      url: 'https://{s}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}',
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      center: [0.432268, 9.421583],
      baseCenter: [0.432268, 9.421583],
      zoom: 6,
      current_device: null
    };
  },
  mounted() {
    this.initMap()
    this.getDevices();
  },
  beforeUnmount() {
    clearInterval(this.syncInterval);
  },
  created () {
    //recharger les positions apres 10sec
    this.syncInterval = setInterval(function(){
      this.getDevices() 
    }.bind(this), 10000);
  },
  methods: {
    initMap(){
      //initialisation de la map
      this.map = L.map(this.$refs.mapContainer, {zoomControl: true,zoom:1,zoomAnimation:false,fadeAnimation:true,markerZoomAnimation:true}).setView(this.center, this.zoom);
      
      let osm = L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', 
      {
        maxZoom: 19,
        attribution: '© OpenStreetMap'
      });

      let osmHOT = L.tileLayer('https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png',
      {
        maxZoom: 19,
        attribution: '© OpenStreetMap contributors, Tiles style by Humanitarian OpenStreetMap Team hosted by OpenStreetMap France'
      });

      let googleMapsLayer = new L.TileLayer(
        "https://{s}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}",
        {
          maxZoom: 20,
          subdomains: ["mt0", "mt1", "mt2", "mt3"],
        }
      );

      let baseMaps = {
        "Google Maps": googleMapsLayer,
        "OpenStreetMap": osm,
        "OpenStreetMap.HOT": osmHOT
      };
  
      googleMapsLayer.addTo(this.map);

      L.control.layers(baseMaps).addTo(this.map);
    },
    getDevices() {
      this.isLoading = true
  
      //marker pour les balises en ligne
      let markerIconOnline = L.icon({
        iconUrl: this.$store.state.api_tracking+'/images/marker-bleu.png',
        iconSize:     [30, 30], 
      });

      //marker pour les balises hors lignes
      let markerIconOffline = L.icon({
        iconUrl: this.$store.state.api_tracking+'/images/marker-noir.png',
        iconSize:     [30, 30], 
      });

      this.axios.get(this.$store.state.api_tracking+'/api/appareils').then((response) => {
        var markers = L.markerClusterGroup();

        //on boucle sur la reponse de l'api pour creer les markeur
          response.data.forEach(element => {

           if (element.latlng != null && element.latlng.length > 0) {
            //on recupere la derniere position de la balise
            let coord = element.latlng[0], marker, popupContent = '<p>'+element.nom+'<br />'+element.statut+'</p>'

            
              //on regarde si on a une balise sélectionné pour centrer la carte
              if (this.current_device != null && this.current_device.id == element.id) {
                this.center = [coord.latitude, coord.longitude]
              }

              //on affiche le markeur en fonction du status
              if (element.statut == 'online') {
                //on cree le marker et popup
                marker = L.marker([coord.latitude, coord.longitude], {icon: markerIconOnline})
                marker.bindPopup(popupContent).openPopup()
                //on ajoute dans le groupe des markers
                markers.addLayer(marker);
              } else  {
                //on cree le marker et popup
                marker = L.marker([coord.latitude, coord.longitude], {icon: markerIconOffline})
                marker.bindPopup(popupContent).openPopup()
                //on ajoute dans le groupe des markers
                markers.addLayer(marker);
              }

              //on recupere les 5 dernieres positions de la balise dispo sur la reponse avec queue
              let line = []
              if (element.queue != null && element.queue.length > 0) {
                element.queue.forEach(lineCoord => {
                  line.push({
                    lat: lineCoord.latitude,
                    lng: lineCoord.longitude,
                  })
                });
              }

              //on cree une ligne avec les 5 dernieres positions
              L.polyline(line, {color: 'red'}).addTo(this.map);
            }
            
          });

          this.map.removeLayer(this.markers);
          this.markers = markers
          this.map.addLayer(markers);
          this.devices = response.data
          this.isLoading = false

      }).catch(() => {
        this.isLoading = false
        this.$toast.add({
            severity: 'error',
            summary: 'Probleme de connexion',
            detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
            life: 5000
        });
      })
    },
    flyToDevice(device){
      
      this.current_device = device
      if (device.latlng != null && device.latlng.length > 0) {
        let coord = device.latlng[0]
        this.map.flyTo([coord.latitude, coord.longitude], 13);
      }
    },
    openFullscreen() {
      var elem = document.getElementById("mapCard");

      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) { /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { /* IE11 */
        elem.msRequestFullscreen();
      }

    }
  },
};
</script>

<style>
.map {
  height: 100vh;
}
</style>