<template>
    <h3 class="mb-3" v-if="current_chat.user && current_chat.user.name">
        <strong>{{ current_chat.user.name }} </strong> ({{current_chat.service}})
    </h3>
    <div class="card card-body">
        <ul class="list-unstyled">
            <li class="mb-1" v-for="message in chat_messages" :key="message.id">
                <div v-if="message.chat_auteur == message.user.id">
                    <span class="badge badge-success rounded-pill p-2">{{ message.content }}</span>
                    <br><small style="font-size: smaller;">{{ message.created_at }}</small>
                </div>
                <div v-else class="text-right ">
                    <span class="badge badge-primary rounded-pill p-2"> {{ message.content }} </span>
                    <br>
                    <small class="mr-2" v-if="this.$store.state.user.id != message.user.id"> {{ message.user.name }} </small>
                    <small style="font-size: smaller;">{{ message.created_at }}</small>
                </div>
            </li>
        </ul>
    </div>
    <create-chat-message :chat_id="current_chat.id" v-on:refresh-messages="getMessages()"></create-chat-message>
</template>
<script>
import { defineComponent } from 'vue';
import CreateChatMessage from './CreateChatMessage.vue';
export default defineComponent({
    name: 'IndexChatMessage',
    components: { CreateChatMessage },
    props: ['chat'],
    data() {
        return {
            isLoading: false,
            table: 'chat_messages',
            chat_messages: [],
            current_chat: this.chat,
        };
    },
    mounted() {
        this.getMessages();
    },
    methods: {
        getMessages() {
            this.isLoading = true;
            this.axios.get('/api/chat_messages?chat_id=' + this.chat.id).then((response) => {
                this.chat_messages = response.data;
                this.isLoading = false;
            }).catch(() => {
                this.isLoading = false;
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            });
        },
    },
});
</script>