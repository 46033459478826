<template>
    <div class="btn-group page-nav" role="group" aria-label="Basic">
        <router-link class="btn" :to="{ name: 'factures' }" :class="{ 'active': this.$route.name === 'factures' }">
            <i class="fa-solid fa-file"></i> Factures
        </router-link>
        <router-link class="btn" :to="{ name: 'achats' }" :class="{ 'active': this.$route.name === 'achats' }" >
            <i class="fa-solid fa-cart-shopping"></i> Achats
        </router-link>
        <router-link class="btn" :to="{ name: 'pieces_de_caisse' }" :class="{ 'active': this.$route.name === 'pieces_de_caisse' }">
            <i class="fa-solid fa-sack-dollar"></i> Pieces de caisse
        </router-link>
        <router-link class="btn" :to="{ name: 'journaux' }" :class="{ 'active': this.$route.name === 'journaux' }">
            <i class="fa-solid fa-list-ul"></i> Journaux
        </router-link>
    </div>
</template>