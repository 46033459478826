import { createStore } from 'vuex'

export default createStore({
  state: {
    isLoading: false,
    authenticated: false,
    api_url: 'https://yamslogistics.com',
    // api_url: 'http://localhost:3400',
    api_tracking: 'https://geoloc.yamslogistics.com',
    // api_tracking: 'http://localhost:7000',
    notifications: 0,
    messages: 0,
    user: {
      name : String,
      telephone1 : String,
      telephone2 : String,
      date_naissance : String,
      adresse  : String,
      photo  : String,
      permissions  : String,
      token: String
    }
  },
  getters: {
    notifications(state) {
      return state.notifications
    },
    messages(state) {
      return state.messages
    },
    authenticated(state) {
      return state.authenticated
    },
    user(state) {
      return state.user
    },
    permissions(state) {
      return state.user.permissions
    },
    apiUrl(state) {
      return state.api_url
    },
  },
  mutations: {
    setNotifications(state, value) {
      state.notifications = value
    },
    setMessages(state, value) {
      state.messages = value
    },
    setAuthenticated(state, value) {
      state.isLoading = true
      state.authenticated = true
      state.user = value
      window.localStorage.setItem('yamsmanager_session', JSON.stringify(value))
    },
    setIsLoading(state, value) {
      state.isLoading = value
    },
    clearUserData(state) {
      state.isLoading = true
      state.authenticated = false
      state.user = {
        name : String,
        telephone1 : String,
        telephone2 : String,
        date_naissance : String,
        adresse  : String,
        photo  : String,
        permissions  : String,
        token: String
      }
      window.localStorage.removeItem('yamsmanager_session')
      state.isLoading = false
    },
    setUserPhoto(state, value) {
      state.user.photo = value
    },
    setUserProfile(state, value) {
      state.isLoading = true
      state.user.name = value.name,
      state.user.telephone1 = value.telephone1,
      state.user.telephone2 = value.telephone2,
      state.user.date_naissance = value.date_naissance,
      state.user.adresse = value.adresse,
      state.user.photo = value.photo
      state.isLoading = false
    }
  },
  actions: {
  },
  modules: {
  }
})