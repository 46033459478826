<template>
    <div>
        <button class="btn btn-primary btn-sm mr-2" @click="download()">
            <i class="fa-solid fa-download"></i> Télécharger
        </button>
        <div class="container">
            <div class="col-8 bg-white ">
                <div id="bordereau">
                    <div class="mb-5">
                        <Entete></Entete>
                    </div>
                    
                    <div class="m-3">
                        <h2 class="text-center my-5">Attestation de stage</h2>

                        <p class="text-justify ">
                            Je soussigné  Monsieur YAHYA MAHAMAT Breme, agissant en qualité de Directeur Général certifie par la présente que 
                            {{ civilite }} <span class="font-weight-bold text-italic">{{ nomComplet }}</span> 
                            a effectué un stage au sein de YAM’S Transport & Logistics du 
                            <span class="text-italic">{{ date_debut }}</span> au <span class="text-italic">{{ date_fin }}</span> .
                        </p>

                        <p class="text-justify mb-5">
                            Cette attestation est délivrée à l’intéressé(e) pour servir et valoir ce que de droit.
                        </p>

                        <div class="d-flex justify-content-end pt-5">
                            <div class="col-6">
                                <h6>Fait à <span class="text-italic">{{ ville }}</span> le : </h6>
                            </div>
                        </div>
                    </div>
                    

                    <!-- <div class="col-6 ml-auto">
                        <img src="@/assets/yams-cachet.png" class="my-4">
                    </div> -->
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import moment from 'moment'
import html2pdf from "html2pdf.js";
import Entete from '@/layouts/EnteteDesExport.vue';


export default {
    name: 'AttestationStage',
    components: { Entete },
    data() {
        return {
            isLoading: false,
            // civilite: 'Mademoiselle',
            // nomComplet: 'IBOUMBI MAMADOU Dolss Veraldienne',
            // date_debut: '05/05/2023',
            // date_fin: '05/08/2023'
            // civilite: 'Mademoiselle',
            // nomComplet: 'MOUANDAMA MPEAH Daphné Serjulie',
            // date_debut: '30/05/2023',
            // date_fin: '30/08/2023'
            civilite: 'Monsieur',
            nomComplet: 'EMORE Freddy Junior',
            date_debut: '30/05/2023',
            date_fin: '30/08/2023',
            ville: 'libreville'
        }
    },

    methods: {
        formatDate(val) {
            if (val) return moment(String(val)).format('DD/MM/YYYY')
        },
        formatPrice(val) {
            if (val) return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'XAF' }).format(val)
        },
        download() {
            // document.getElementById('footer-text').style.display = 'none'
            html2pdf().set({
                pagebreak: { mode: 'avoid-all' },
                jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
                margin: [0.3, 0.3],
                filename: "Attestation-stage",
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 }
            }).from(document.getElementById("bordereau"))
                .toPdf()
                .get('pdf')
                .then(function (pdf) {
                    var totalPages = pdf.internal.getNumberOfPages();
                    pdf.setFontSize(10);
                    pdf.setTextColor(150);
                    for (var i = 1; i <= totalPages; i++) {
                        pdf.setPage(i);
                        pdf.text(' S.A.R.L au capital de 1.000.000 F CFA | Oloumi - BP: 1073 Libreville | Tel: +241 (0) 1 74 78 91 - 062 13 71 78 ' + "\n" + 'RCCM: GA-LBV-01-2020-B13-00255 / NIF: 054889 C  |Email: infos@yamslogistics.com / Site web: yamslogistics.com', pdf.internal.pageSize.getWidth() - 0.50, pdf.internal.pageSize.getHeight() - 0.50, { align: 'right' });
                    }
                })
                .save();
        }
    },
}
</script>
<style scoped>
.details {
    font-size: 12px;
}

#bordereau {
    width: 100%;
}
</style>