<template>
        <button type="button" class="btn btn-light rounded-circle mr-2" data-toggle="dropdown" @click="openShowModal()">
            <i class="fa-solid fa-envelope text-xl"></i>
            <span v-if="this.$store.getters.messages > 0" class="compteur badge badge-danger navbar-badge">
                {{ this.$store.getters.messages }}
            </span>
        </button>
        <!-- <div class="dropdown-menu dropdown-menu-right p-0" style="width: 25vw; max-height: 80vh; max-width: 25vw; overflow-y: auto;  overflow-x: hidden;">
            <ProgressBar v-if="isLoading === true" mode="indeterminate" style="height: 6px;"></ProgressBar>
            <table class="table">
                <thead>
                    <tr>
                        <th class="d-flex">
                            <div>
                                Nouveaux messages
                            </div>
                            <button v-if="messages.length > 0" class="btn btn-outline-dark btn-sm my-1 ml-auto" type="button"
                                @click.prevent="readAll()">
                                Tout marquer comme lu
                            </button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="mess in this.messages" :key="mess.id">
                        <td>
                            <button class="btn text-justify btn-block" type="button" @click="openShowModal(mess)">
                                <strong> {{ mess.nom }} </strong>
                                <br>{{ mess.objet }}
                            </button>
                        </td>
                    </tr>
                    <p v-if="messages.length < 1" class="text-center font-italic py-3">
                        Pas de nouveaux messages
                    </p>
                </tbody>
                <tfoot>
                    <router-link :to="{name: 'messages.index'}" class="btn btn-sm btn-block text-primary">
                        Afficher tout
                    </router-link> 
                </tfoot>
            </table>
        </div> -->
</template>

<script>
// import ShowMessageVue from "@/views/messages/ShowMessage.vue";
import { defineComponent, defineAsyncComponent } from 'vue';
const IndexMessage = defineAsyncComponent(() => import('@/views/messages/IndexMessage.vue'));

export default defineComponent({
    name: 'App',
    data() {
        return {
            isLoading: true,
        }
    },
    created() {
        this.getMessages()
    },
    mounted() {
        // const self = this
        // setInterval(function(){
        //     self.getMessages()
        // }, 120000);
    },
    methods: {
        openShowModal() {
            this.$dialog.open(IndexMessage, {
                props: {
                    header: "Messages",
                    style: {
                        width: '70vw'
                    },
                    modal: true
                }
            });
        },
        // showLibelle(val) {
        //     let notification = JSON.parse('[' + val + ']')
        //     let libelle = notification[0].libelle.substr(0, 32)
        //     if (String(libelle).length > 33) return libelle + '...'
        //     else return libelle + '...'
        // },
        async getMessages() {
            await this.axios.get('/api/messages?read_at=null&req_count').then((response) => {
                this.$store.commit('setMessages', response.data)
                this.isLoading = false
            }).catch(() => {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
                this.isLoading = false
            })
        },
        
        // openShowModal(objData) {
        //     if (objData.read_at == null) {
        //         this.axios.post('/api/messages/' + objData.id + '/read').then(() => {
        //             this.getMessages()
        //         })
        //     }
        //     this.$dialog.open(ShowMessageVue, {
        //         props: {
        //             header: 'Objet: ' + objData.objet,
        //             style: {
        //                 width: '40vw'
        //             },
        //             modal: true
        //         },
        //         data: {
        //             table: this.table,
        //             datas: objData
        //         }
        //     });
        // }
    }
})
</script>

<style scoped>
button:hover {
    background-color: #3142C5;
    color: #fff !important;
}
.compteur {
    margin: -5px;
}
</style>
