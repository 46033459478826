<template>
    <div class="btn-group page-nav " role="group" >
        <router-link class="btn" :to="{ name: 'employes' }" :class="{ 'active': this.$route.name === 'employes' }">
            <i class="fa-solid fa-users"></i> Employes
        </router-link>
        <router-link class="btn" :to="{ name: 'affectations' }" :class="{ 'active': this.$route.name === 'affectations' }">
            <i class="fa-solid fa-user"></i> Affectations
        </router-link>
        <router-link class="btn" :to="{ name: 'contrats' }" :class="{ 'active': this.$route.name === 'contrats' }">
            <i class="fa-solid fa-folder"></i> Contrats
        </router-link>
        <router-link class="btn" :to="{ name: 'conges' }" :class="{ 'active': this.$route.name === 'conges' }">
            <i class="fa-solid fa-suitcase"></i> Congés
        </router-link>
    </div>
</template>

<script >
import { defineComponent } from 'vue';

export default defineComponent ({
    methods: {
        getPermission(permission) {
            return this.$store.getters.permissions.includes(permission)
        },
    },
});
</script>