<template>
  <div class="header-contentys pt-3 d-flex align-items-center">
    <div class="mr-5">
      <h4 class="text-primary font-weight-bold">Achats ({{ lines }})</h4>
    </div>
    <div class="mr-4">
      <NavComptabilite></NavComptabilite>
    </div>
    <div class="mr-4">
      <div class="btn-group page-nav" role="group" aria-label="Basic">
        <a href="" class="btn" @click.prevent="changeAffichage('list')" :class="{ 'active': affichage === 'list' }">
          <i class="fa-solid fa-bars"></i>
        </a>
        <a href="" class="btn " @click.prevent="changeAffichage('cards')" :class="{ 'active': affichage === 'cards' }">
          <i class="fa-solid fa-border-all"></i>
        </a>
      </div>
    </div>
    <div class="mr-2">
      <button type="button" class="btn-yams btn-active" @click="openCreateModal()">
        Ajouter un achat <i class="fa-solid fa-plus"></i>
      </button>
    </div>
  </div>
  <hr>
  <div class="body-contentys">
    <DataTableBackend
     :key="key" url="/api/achats" :columns="columns" :affichage="affichage" :table="table" selection inCard :idParams="this.$route.params.id"
    v-on:set-total-records="updateLines">
      <template #action_group_btns=slotProps>
        <button type="button" class="btn btn-sm btn-danger mr-2" @click="deleteGroup(slotProps.data)">
          <i class="fas fa-trash-alt"></i> Supprimer
        </button>
      </template>
      <template #action_btns=slotProps>
        <button class="btn btn-sm btn-outline-dark" @click="openShowModal(slotProps.data)">
          <i class="fa-solid fa-eye"></i>
        </button>
      </template>
    </DataTableBackend>
  </div>
</template>
<script>
import $ from "jquery"
import { defineComponent, defineAsyncComponent } from 'vue';
import NavComptabilite from '../NavComptabilite.vue'
const CreateAchat = defineAsyncComponent(() => import('./CreateAchat.vue'));
const ShowAchat = defineAsyncComponent(() => import('./ShowAchat.vue'));

export default defineComponent({
  components: { NavComptabilite },
  name: 'IndexAchat',
  data() {
    return {
      lines: 0,
      key:1,
      isLoading: true,
      table: 'achats',
      affichage: "list",
      columns: [
        {
          key: 'date_operation',
          label: 'Date',
          format: 'date',
          exportable: true
        },
        {
          key: 'user.name',
          label: 'Acheteur',
          exportable: true
        },
        {
          key: 'fournisseur.nom',
          label: 'Fournisseur',
          exportable: true
        },
        {
          key: 'montant',
          label: 'Montant',
          format: 'money',
          exportable: true
        },
        {
          key: 'statut',
          label: 'Statut',
          format: 'state'
        }
      ]
    };
  },
  methods: {
    getPermission(permission) {
      return this.$store.getters.permissions.includes(permission)
    },
    changeAffichage(type) {
      this.affichage = type;
       this.key++
    },
    openShowModal(objData) {
      this.$dialog.open(ShowAchat, {
        props: {
          header: "Achat ID" + objData.id,
          style: {
            width: '80vw'
          },
          modal: true
        },
        data: objData
      });
    },
    openCreateModal() {
      this.$dialog.open(CreateAchat, {
        props: {
          header: "Nouvel achat",
          style: {
            width: '80vw'
          },
          modal: true
        }
      });
    },
    deleteGroup(selection) {
      if (!window.confirm("Etes vous sur de vouloir supprimer ?")) {
        return
      }

      this.isLoading = true
      let selectedLines = []
      for (let index = 0; index < selection.length; index++) {
        const element = selection[index];
        selectedLines.push(element.id)
      }

      this.axios.post('/api/achats/destroy-group', { selected_lines: selectedLines }).then((response) => {
        this.isLoading = false
        if (response.data.success === true) {
          $('#refresh' + this.table).click()
          for (let index = 0; index < response.data.message.length; index++) {
            const element = response.data.message[index];
            this.$toast.add({
              severity: element.severity,
              detail: element.value,
              life: parseInt(5000 * (index + 1))
            });
          }
        }
      }).catch(() => {
        this.isLoading = false
        this.$toast.add({
          severity: 'error',
          summary: 'Probleme de connexion',
          detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
          life: 5000
        });
      })
    },
    updateLines(nb_lines) {
        this.lines = nb_lines
    }
  }
});
</script>
    