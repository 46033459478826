import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { id: "yamsmanager" }
const _hoisted_2 = {
  key: 0,
  class: "dashboard"
}
const _hoisted_3 = {
  id: "contentys",
  class: "container-fluid"
}
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TempNavBar = _resolveComponent("TempNavBar")!
  const _component_TempSideBar = _resolveComponent("TempSideBar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_TempFooter = _resolveComponent("TempFooter")!
  const _component_ShowToast = _resolveComponent("ShowToast")!
  const _component_DynamicDialog = _resolveComponent("DynamicDialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (this.$store.getters.authenticated)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_TempNavBar),
          _createVNode(_component_TempSideBar),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_router_view)
          ]),
          _createVNode(_component_TempFooter)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_router_view)
        ])),
    _createVNode(_component_ShowToast),
    _createVNode(_component_DynamicDialog)
  ]))
}