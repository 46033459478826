<template>
    <div class="header-contentys pt-3 d-flex align-items-center">
      <div class="mr-5">
        <h4 class="text-primary font-weight-bold">Conversations</h4>
      </div>
      <div class="mr-4">
        <NavOperations></NavOperations>
      </div>
      <div class="px-1">
          <button type="button" class="btn-yams btn-active" @click="openCreateModal()">
              Ajouter une conversation <i class="fa-solid fa-plus"></i>
          </button>
      </div>
    </div>
    <hr>
    <div class="body-contentys row ">
      <button type="button" id="refreshchats" @click="getChats()" hidden></button>
      <ProgressBar v-if="isLoading === true" mode="indeterminate" style="height: 6px"></ProgressBar>
      <div class="col-3" v-if="chats.length > 0">
        <div class="card card-body p-2">
          <ul class="list-group list-group-flush">
            <li class="list-group-item" v-for="chat in chats" :key="chat.id" :class="{'bg-chat-active': (current_chat.id == chat.id)}" >
              <span class="btn" type="button" @click="openChat(chat)">
                {{ chat.user.name }} <span class="text-secondary font-italic">(Pour {{ chat.service }})</span>
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-9" v-if="current_chat !== ''">
        <index-chat-message :key="key" :chat="current_chat"></index-chat-message>
      </div>
    </div>
  </template>
        
  <script>
  import { defineComponent, defineAsyncComponent } from 'vue';
  import NavOperations from '../NavOperations.vue';
  import IndexChatMessage from './chat_messages/IndexChatMessage.vue';
  const CreateChat = defineAsyncComponent(() => import('./CreateChat.vue'));
  export default defineComponent({
    name: 'IndexChat',
    components: { NavOperations, IndexChatMessage },
    data() {
      return {
        // lines: 0,
        isLoading: false,
        table: 'chats',
        chats: [],
        key: 1,
        current_chat:''
      };
    },
    mounted() {
      this.getChats()
    },
    methods: {
      openChat(chat) {
        this.current_chat = chat
        this.key++
      },
      openCreateModal() {
            this.$dialog.open(CreateChat, {
                props: {
                    header: "Nouvelle conversation",
                    style: {
                        width: '40vw'
                    },
                    modal: true
                }
            });
        },
      getChats() {
            this.isLoading = true
            this.axios.get('/api/chats').then((response) => {
                this.chats = response.data
                this.isLoading = false
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
    }
  
  });
  </script>
  
  <style>
  .bg-chat-active {
    background: #e3e2e2  
  }
  </style>