<template>
    <div class="card">
        <div class="card-header">
            Nombre de courses par mois
        </div>
        <div class="card-body p-0">
            <ProgressBar v-if="isLoading === true" mode="indeterminate" style="height: 6px"></ProgressBar>
            <ChartJS type="bar" :data="CoursesParMois" :options="chartOptions" class="h-30rem" />
        </div>
    </div>
</template>

<script >
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'CamionParTypes',
    data() {
        return {
            isLoading: false,
            CoursesParMois: {
                datasets: [
                    {
                        label: 'Courses',
                        data: [],
                        backgroundColor: '#0C266C',
                        borderColor: '#505050',
                        borderWidth: 1
                    }
                ]
            },
            chartOptions: null,
        }
    },
    mounted() {
        this.chartOptions = this.setChartOptions();
        this.getCoursesParMois();
    },
    methods: {
        getCoursesParMois() {
            this.isLoadingCoursesParMois = true
            this.axios.get('/api/courses?nombre_par_mois=1').then((response) => {
                this.CoursesParMois.datasets = [
                    {
                        label: 'Courses',
                        data: response.data,
                        backgroundColor: '#0C266C',
                        borderColor: '#505050',
                        borderWidth: 1
                    }
                ]
                this.isLoadingCoursesParMois = false
            }).catch(() => {
                this.isLoadingCoursesParMois = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            });
        },
        setChartOptions() {
            const documentStyle = getComputedStyle(document.documentElement);
            const textColor = documentStyle.getPropertyValue('--text-color');
            const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
            const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

            return {
                maintainAspectRatio: false,
                aspectRatio: 0.8,
                plugins: {
                    tooltips: {
                        mode: 'index',
                        intersect: false
                    },
                    legend: {
                        labels: {
                            color: textColor
                        }
                    }
                },
                scales: {
                    x: {
                        stacked: true,
                        ticks: {
                            color: textColorSecondary
                        },
                        grid: {
                            color: surfaceBorder
                        }
                    },
                    y: {
                        stacked: true,
                        ticks: {
                            color: textColorSecondary
                        },
                        grid: {
                            color: surfaceBorder
                        }
                    }
                }
            };
        }
    },
});
</script>
  