
  import moment from 'moment'
  import { FilterMatchMode } from 'primevue/api';
  import { defineComponent } from 'vue';
  export default defineComponent({
    name: "TopDataTable",
    props: {
      solde: { type: Boolean },
      url: { type: String },
      affichage: { type: String },
      table: { type: String },
      period_filter: { type: Boolean },
      showTotalMontant: { type: Boolean }
    },
    data() {
      return {
        datas : [],
        showEntries: [5, 10, 25, 50, 100, 500],
        rows: 10,
        page: 1,
        filters: {
          global: { value: null, matchMode: FilterMatchMode.CONTAINS }
        },
        isLoading: true,
        period: moment().format('YYYY-MM'),
        currentPeriod: moment().format('YYYY-MM'),
        key: 1,
        total_montant : 0,
        total_debit : 0,
        total_credit : 0
      };
    },
    mounted() {
      this.getDatas();
    },
    methods: {
      getDatas() {
        this.isLoading = true
        let link = this.url
        if (link !== undefined && link.includes('?')) link = link+'&'
        if (link !== undefined && link.includes('?') && this.period_filter === true) {
          link += '&req_period='+this.period
        }
        else if (link !== undefined && this.period_filter === true) {
          link += '?req_period='+this.period
        }
        else link = link+'?'

        this.axios.get(link).then((response) => {
          this.datas = response.data
          if (this.showTotalMontant === true) {
            this.total_montant = 0
            for (let index = 0; index < response.data.length; index++) {
              const element = response.data[index];
              this.total_montant = this.total_montant + parseInt(element.montant)
            }
          }

          if (this.solde === true) {
            this.total_debit = 0,
            this.total_credit = 0
            for (let index = 0; index < response.data.length; index++) {
              const element = response.data[index];
              if (element.solde == 'Debit' &&  parseInt(element.montant)) {
                this.total_debit = this.total_debit + parseInt(element.montant)
              }
              if (element.solde == 'Credit' &&  parseInt(element.montant)) {
                this.total_credit = this.total_credit + parseInt(element.montant)
              }
            }
          }
          
          this.isLoading = false
        }).catch(() => {
          this.isLoading = false
          this.$toast.add({
            severity: 'error',
            summary: 'Probleme de connexion',
            detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
            life: 5000
          });
        });
        
      },
      getTotalMontant() {
        this.isLoading = true
        let link = this.url
        if (link !== undefined && this.period_filter === true) {
          link += '?req_period='+this.period
        }
        if (link !== undefined && link.includes('?')) link = link+'&req_count'
        else link = link+'?req_count'

        this.axios.get(link).then((response) => {
          this.total_montant = response.data
          this.isLoading = false
        }).catch(() => {
          this.isLoading = false
          this.$toast.add({
            severity: 'error',
            summary: 'Probleme de connexion',
            detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
            life: 5000
          });
        });
        
      },
      refreshSlot() {
        this.key++
      },
      resetPeriod() {
        this.period = this.currentPeriod;
        this.getDatas()
      },
      onCHangePeriod () {
        this.isLoading = true
        if (this.url !== undefined) {
          this.axios.get(this.url).then((response) => {
            this.datas = response.data
            this.isLoading = false
          }).catch(() => {
            this.isLoading = false
            this.$toast.add({
              severity: 'error',
              summary: 'Probleme de connexion',
              detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
              life: 5000
            });
          });
        }
      }
    }
  })
  