<template>
    <div id="footer" class="container-fluid">
      <div class="d-flex justify-content-between py-2">
        <span class="">
          Copyright &copy; {{ new Date().getFullYear() }} YAMS Digital Service
        </span>
        <span class="px-2">
          Version {{ version }}
        </span>
      </div>
    </div>
  </template>
  
  <script>
    import * as PackageJson from "../../package.json"
    export default {
      data() {
        return {
          version: PackageJson.version
        }
      } 
    }
  </script>