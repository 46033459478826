<template>
  <div class="header-contentys pt-3 d-flex align-items-center">
    <div class="mr-5">
      <h4 class="text-primary font-weight-bold">Journaux</h4>
    </div>
    <div class="mr-4">
      <NavComptabilite></NavComptabilite>
    </div>
    <!-- <div class="mr-4">
      <div class="btn-group page-nav" role="group" aria-label="Basic">
        <a href="" class="btn" @click.prevent="changeAffichage('list')" :class="{ 'active': affichage === 'list' }">
          <i class="fa-solid fa-bars"></i>
        </a>
        <a href="" class="btn " @click.prevent="changeAffichage('cards')" :class="{ 'active': affichage === 'cards' }">
          <i class="fa-solid fa-border-all"></i>
        </a>
      </div>
    </div> -->
    <!-- <div class="mr-2">
            <button type="button" class="btn-yams btn-active" @click="openCreateModal()" >
                Ajouter une operation <i class="fa-solid fa-plus"></i>
            </button>
        </div> -->
  </div>
  <hr>
  <div class="body-contentys row">
    <div class="col-3">
      <div class="card card-body p-2">
        <form @submit.prevent="refreshUrl()">
          <div class="form-group">
            <label>Type </label>
            <select class="form-control" v-model="form.type">
                <option >Achat</option>
                <option >Vente</option>
                <option >Operation Diverse</option>
                <option >Banque</option>
                <option >Caisse</option>
            </select>
          </div>
          <div class="form-group">
            <label>Date debut</label>
            <input type="date" class="form-control" v-model="form.date_debut" required />
          </div>
          <div class="form-group">
            <label>Date fin</label>
            <input type="date" class="form-control" v-model="form.date_fin" required />
          </div>
          <div class="form-group">
            <label>Compte</label>
            <input type="text" class="form-control" v-model="form.compte" />
          </div>
          <div class="d-flex justify-content-between">
            <button type="button" class="btn btn-dark" v-if="url != '/api/operations'">
              <i class="fa-solid fa-refresh "></i> Reinitialiser
            </button>
            <button type="submit" class="btn btn-primary" :disabled="isLoading">
              <i class="fa-solid fa-refresh "></i> Charger
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="col-9">
      <DataTableBackend :url="url" :key="key" :columns="columns" :affichage="affichage" :table="table" :perPage="12" inCard noTopBar>
        <template #action_btns=slotProps>
          <button class="btn btn-sm btn-outline-dark" @click="openShowModal(slotProps.data)">
            <i class="fa-solid fa-eye"></i>
          </button>
        </template>
      </DataTableBackend>
    </div>

  </div>
</template>
      
<script>
import { defineComponent, defineAsyncComponent } from 'vue';
import NavComptabilite from '../NavComptabilite.vue'
// const CreateJournaux = defineAsyncComponent(() => import('./CreateJournaux.vue'));
const ShowJournaux = defineAsyncComponent(() => import('./ShowJournaux.vue'));
export default defineComponent({
  components: { NavComptabilite },
  name: 'IndexAchat',
  data() {
    return {
      // lines: 0,
      isLoading: false,
      table: 'operations',
      affichage: "list",
      url: '/api/operations',
      key: 1,
      form: {
        type: '',
        date_debut: '',
        date_fin: '',
        compte: ''
      },
      types: [
        'Achat',
        'Vente',
        'Operation Diverse',
        'Banque',
        'Caisse'
      ],
      columns: [
        {
          key: 'date_operation',
          label: 'Date',
          format: 'date',
          exportable: true
        },
        {
          key: 'type',
          label: 'Type',
          format: 'state',
          exportable: true
        },
        {
          key: 'compte',
          label: 'Compte',
          exportable: true
        },
        {
          key: 'libelle',
          label: 'Libelle',
          exportable: true
        },
        {
          key: 'solde',
          label: 'Solde',
          format: 'state',
          exportable: true
        },
        {
          key: 'montant',
          label: 'Montant',
          format: 'money',
          exportable: true
        }

      ]
    };
  },
  methods: {
    getPermission(permission) {
      return this.$store.getters.permissions.includes(permission)
    },
    changeAffichage(type) {
      this.affichage = type;
      this.key++
    },
    openShowModal(objData) {
      this.$dialog.open(ShowJournaux, {
        props: {
          header: "Operation ID" + objData.id,
          style: {
            width: '40vw'
          },
          modal: true
        },
        data: objData
      });
    },
    // openCreateModal() {
    //   this.$dialog.open(CreateJournaux, {
    //     props: {
    //       header: "Nouvelle opération",
    //       style: {
    //         width: '40vw'
    //       },
    //       modal: true
    //     }
    //   });
    // },
    // getClients() {
    //   this.isLoading = true
    //   this.axios.get('/api/users?type_user=client').then((response) => {
    //     this.clients = response.data
    //     this.isLoading = false
    //   }).catch(() => {
    //     this.isLoading = false
    //     this.$toast.add({
    //       severity: 'error',
    //       summary: 'Probleme de connexion',
    //       detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
    //       life: 5000
    //     });
    //   })
    // },
    // getFournisseurs() {
    //   this.isLoading = true
    //   this.axios.get('/api/fournisseurs').then((response) => {
    //     this.fournisseurs = response.data
    //     this.isLoading = false
    //   }).catch(() => {
    //     this.isLoading = false
    //     this.$toast.add({
    //       severity: 'error',
    //       summary: 'Probleme de connexion',
    //       detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
    //       life: 5000
    //     });
    //   })
    // },
    // onTypeChange() {
    //   if (this.form.type == 'Achat') {
    //     this.getFournisseurs()
    //   }
    //   else if (this.form.type == 'Vente') {
    //     this.getClients()
    //   }
    // },
    refreshUrl() {
      this.url = '/api/operations?type=' + this.form.type + '&date_debut=' + this.form.date_debut + '&date_fin=' + this.form.date_fin+ '&compte=' + this.form.compte
      this.key++
    },
    resetFilter() {
      this.url = '/api/operations'
      this.key++
    },
    // updateLines(nb_lines) {
    //   this.lines = nb_lines
    // }
  }

});
</script>
