<template>
    <div class="header-contentys pt-3 d-flex align-items-center">
        <div class="mr-5">
            <h4 class="text-primary font-weight-bold">Demandes ({{ lines }})</h4>
        </div>
        <div class="mr-4">
            <NavOperations></NavOperations>
        </div>
        <div class="btn-group page-nav mr-4" role="group" aria-label="Basic example">
            <a href="" class="btn " @click.prevent="changeAffichage('list')" :class="{ 'active': affichage === 'list' }">
                <i class="fa-solid fa-bars"></i>
            </a>
            <a href="" class="btn " @click.prevent="changeAffichage('cards')" :class="{ 'active': affichage === 'cards' }">
                <i class="fa-solid fa-border-all"></i>
            </a>
        </div>
        <div>
            <button type="button" class="btn-yams btn-active" @click="openCreateModal()">
                Ajouter une demande <i class="fa-solid fa-plus"></i>
            </button>
        </div>
    </div>
    <hr>
    <div class="body-contentys ">
        <DataTableBackend url="/api/course_demandes" :idParams="this.$route.params.id" :columns="columns" :affichage="affichage" :table="table" selection inCard v-on:set-total-records="updateLines">
            <template #action_group_btns=slotProps>
                <button type="button" class="btn btn-sm btn-danger mr-2" @click="deleteGroup(slotProps.data)">
                    <i class="fas fa-trash-alt"></i> Supprimer
                    <span v-if="isLoading == true" class="spinner-border spinner-border-sm" role="status"
                        aria-hidden="true"></span>
                </button>
            </template>
            <template #action_btns=slotProps>
                <button class="btn btn-sm btn-outline-dark" @click="openShowModal(slotProps.data)">
                    <i class="fa-solid fa-eye"></i>
                </button>
            </template>
        </DataTableBackend>
    </div>
</template>
  
<script >
import $ from "jquery"
import { defineComponent, defineAsyncComponent } from 'vue';
import NavOperations from '../../NavOperations.vue';
const CreateDemande = defineAsyncComponent(() => import('./CreateDemande.vue'));
const ShowDemande = defineAsyncComponent(() => import('./ShowDemande.vue'));

export default defineComponent({
    name: 'IndexCourse',
    components: { NavOperations },
    data() {
        return {
            lines: 0,
            key:1,
            affichage: "list",
            table: 'course_demandes',
            columns: [
                {
                    key: 'client.nom',
                    label: 'Client',
                    exportable: true
                },
                {
                    key: 'type_course',
                    label: 'Type',
                    exportable: true
                },
                {
                    key: 'trajet.libelle',
                    class: 'bg-secondary p-1 rounded text-white text-center',
                    stringWithKeys: [
                        {
                            var: 'adresse_chargement',
                            text: '('
                        },
                        {
                            var: 'date_chargement',
                            text: ') -',
                            format: 'date'
                        },
                        {
                            var: 'adresse_dechargement',
                            text: '('
                        },
                        {
                            var: 'date_dechargement',
                            text: ')',
                            format: 'date'
                        }
                    ],
                    label: 'Trajet',
                    exportable: true
                },
                // {
                //     key: 'trajet.montant',
                //     label: 'Montant',
                //     format: 'money',
                //     exportable: true
                // },
                {
                    key: 'statut',
                    label: 'Statut',
                    format: 'state'
                }
            ]
        };
    },
    methods: {
        getPermission(permission) {
            return this.$store.getters.permissions.includes(permission)
        },
        changeAffichage(type) {
            this.affichage = type;
             this.key++
        },
        openShowModal(objData) {
            this.$dialog.open(ShowDemande, {
                props: {
                    header: "Demande de course ID" + objData.id,
                    style: {
                        width: '50vw'
                    },
                    modal: true
                },
                data: objData
            });
        },
        openCreateModal() {
            this.$dialog.open(CreateDemande, {
                props: {
                    header: "Nouvelle demande de course",
                    style: {
                        width: '50vw'
                    },
                    modal: true
                }
            });
        },
        deleteGroup(selection) {
            if (!window.confirm("Etes vous sur de vouloir supprimer ?")) {
                return
            }

            this.isLoading = true
            let selectedLines = []
            for (let index = 0; index < selection.length; index++) {
                const element = selection[index];
                selectedLines.push(element.id)
            }

            this.axios.post('/api/course_demandes/destroy-group', { selected_lines: selectedLines }).then((response) => {
                this.isLoading = false
                if (response.data.success === true) {
                    $('#refresh' + this.table).click()
                    for (let index = 0; index < response.data.message.length; index++) {
                        const element = response.data.message[index];
                        this.$toast.add({
                            severity: element.severity,
                            detail: element.value,
                            life: parseInt(5000 * (index + 1))
                        });
                    }
                }
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        updateLines(nb_lines) {
            this.lines = nb_lines
        }
    }
});
</script>