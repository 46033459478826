<template>
    <div class="header-contentys pt-3 d-flex align-items-center">
        <div class="mr-5">
            <h4 class="text-primary font-weight-bold">Calendrier</h4>
        </div>
        <div class="mr-4">
            <NavOperations></NavOperations>
        </div>
        <div>
            <button class="btn btn-outline-dark" @click="openFullscreen()">Basculer en plein écran</button>
        </div>
    </div>
    <hr>
    <div class="body-contentys ">
        <ProgressBar v-if="isLoading === true" mode="indeterminate" style="height: 6px; margin-bottom: 10px"></ProgressBar>
        <div class="card card-body p-1" id="mapCalendar">
           <FullCalendar :options='calendarOptions'>
                <template v-slot:eventContent='arg'>
                    <p href="#" class="btn text-wrap text-white" @click.prevent="showEvent(arg.event.extendedProps)">
                        {{ arg.event.title }}
                    </p>
                </template>
            </FullCalendar> 
        </div>
        
    </div>
</template>
  
<script>
import moment from 'moment'
import { defineComponent, defineAsyncComponent } from 'vue';
const ShowCourse = defineAsyncComponent(() => import('../courses/ShowCourse.vue'));
const ShowIntervention = defineAsyncComponent(() => import('@/views/parc-auto/interventions/ShowIntervention.vue'));
const ShowDetailDocument = defineAsyncComponent(() => import('./ShowDetailDocument.vue'));
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import NavOperations from '../NavOperations.vue';

export default defineComponent({
    components: { FullCalendar, NavOperations },
    data() {
        return {
            isLoading: false,
            lastID: 1,
            calendarOptions: {
                plugins: [dayGridPlugin, bootstrapPlugin],
                themeSystem: 'bootstrap',
                initialView: 'dayGridMonth',
                events: [],
                locale: 'fr',
                height: 'auto',
                datesSet: this.handleMonthChange,
            },
            moisPlay: null
        }
    },
    mounted() {
        this.refreshDatas(moment().format('YYYY-MM'))
    },
    methods: {
        handleMonthChange(payload) {
            const newDate = payload.view.currentStart
            const mois = newDate.getMonth() + 1
            const annee = newDate.getFullYear()
            this.refreshDatas(moment().format(annee + '-' + mois))
        },
        async refreshDatas(YYYYmm) {
            await this.getCourses(YYYYmm)
            await this.getInterventions(YYYYmm)
            await this.getCamionDocuments(YYYYmm)
        },
        getCourses(period) {
            this.isLoading = true
            this.axios.get('/api/courses?req_period=' + period).then((response) => {
                response.data.forEach(element => {
                    this.calendarOptions.events.push({
                        title: 'Départ course ' + element.camion.plaque + ' ' + element.adresse_chargement + ' - ' + element.adresse_dechargement,
                        date: element.date_chargement,
                        color: 'blue',
                        extendedProps: {
                            id: element.id,
                            table: 'courses',
                            data: element
                        },
                        id: this.lastID++
                    })
                    if (element.date_dechargement) {
                        this.calendarOptions.events.push({
                            title: 'Arrivée course ' + element.camion.plaque + ' ' + element.adresse_chargement + ' - ' + element.adresse_dechargement,
                            date: element.date_dechargement,
                            color: 'green',
                            extendedProps: {
                                id: element.id,
                                table: 'courses',
                                data: element
                            },
                            id: this.lastID++
                        })
                    }
                });
                this.isLoading = false
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        getInterventions(period) {
            this.isLoading = true
            this.axios.get('/api/interventions?req_period=' + period).then((response) => {
                response.data.forEach(element => {
                    this.calendarOptions.events.push({
                        title: 'Début ' + element.type + ' ' + element.camion.plaque,
                        date: element.debut,
                        color: 'pink',
                        extendedProps: {
                            id: element.id,
                            table: 'interventions',
                            data: element
                        },
                        id: this.lastID++
                    })
                    if (element.fin) {
                        this.calendarOptions.events.push({
                            title: 'Sortie ' + element.type + ' ' + element.camion.plaque,
                            date: element.fin,
                            color: '#ec8238',
                            extendedProps: {
                                id: element.id,
                                table: 'interventions',
                                data: element
                            },
                            id: this.lastID++
                        })
                    }
                });
                this.isLoading = false
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        getCamionDocuments(period) {
            this.isLoading = true
            this.axios.get('/api/camion_documents?req_period=' + period).then((response) => {
                response.data.forEach(element => {
                    if (element.camion_id !== null) {
                        this.calendarOptions.events.push({
                            title: 'Expiration ' + element.libelle + " de "+element.association,
                            date: element.date_expiration,
                            color: 'red',
                            extendedProps: {
                                id: element.id,
                                table: 'documents',
                                data: element
                            },
                            id: this.lastID++
                        })
                    }
                    else if (element.user_id !== null) {
                        this.calendarOptions.events.push({
                            title: 'Expiration ' + element.libelle + " de "+element.association,
                            date: element.date_expiration,
                            color: 'red',
                            extendedProps: {
                                id: element.id,
                                table: 'documents',
                                data: element
                            },
                            id: this.lastID++
                        })
                    }
                });
                this.isLoading = false
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        showEvent(event) {
            if (event.table == 'courses') {
                this.$dialog.open(ShowCourse, {
                    props: {
                        header: "Course ID" + event.id,
                        style: {
                            width: '50vw'
                        },
                        modal: true
                    },
                    data: event.data
                });
            }
            else if (event.table == 'interventions') {
                this.$dialog.open(ShowIntervention, {
                    props: {
                        header: "Intervention ID" + event.id,
                        style: {
                            width: '50vw'
                        },
                        modal: true
                    },
                    data: event.data
                });
            }
            else if (event.table == 'documents') {
                this.$dialog.open(ShowDetailDocument, {
                    props: {
                        header: "Document ID" + event.id,
                        style: {
                            width: '30vw'
                        },
                        modal: true
                    },
                    data: event.data
                });
            }
        },
        openFullscreen() {
            var elem = document.getElementById("mapCalendar");
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.webkitRequestFullscreen) { /* Safari */
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) { /* IE11 */
                elem.msRequestFullscreen();
            }
        }
    }
})
</script>

  