<template>
    <div class="header-contentys pt-3 d-flex align-items-center">
        <div class="mr-5">
            <h4 class="text-primary font-weight-bold">Tableau de control </h4>
        </div>
    </div>
    <hr>
    <div class="body-contentys">
        <div class="row">
            <div class="col-4" v-if="getPermission('voir-roles') === true">
                <div class="card">
                    <div class="card-body">
                        <h5>Roles</h5>
                        <p>Gerer les permissions et affecter les roles aux utilisateurs.</p>
                        <button type="button" class="btn btn-sm btn-outline-dark "  @click="openRoleModal()">
                            <i class="fa-solid fa-gear"></i> Gerer
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <hr>

        <DataTableBackend  url="/api/activites" :columns="activitesColumns" table="clients" :perPage="10" inCard>
            <!-- <template #action_btns=slotProps>
                <button class="btn btn-sm btn-outline-dark" @click="openShowModal(slotProps.data)">
                    <i class="fa-solid fa-eye"></i>
                </button>
            </template> -->
        </DataTableBackend>

    </div>
</template>
  
<script >
import { defineComponent, defineAsyncComponent } from 'vue';
const IndexRole = defineAsyncComponent(() => import('./roles/IndexRole.vue'));

export default defineComponent({
    name: 'IndexConfig',
    data() {
        return {
            activitesColumns: [
                // {
                //     key: 'user.name',
                //     label: 'Utilisateur',
                //     exportable: true
                // },
                // {
                //     key: 'libelle',
                //     label: 'Action',
                //     exportable: true
                // },
                {
                    key: 'id',
                    stringWithKeys: [
                        {
                            var: 'user.name',
                            text: ' a '
                        },
                        {
                            var: 'libelle',
                        }
                    ],
                    label: 'Actions effectué par utilisateurs'
                },
            ],
        }
    },
    methods: {
        openRoleModal(objData) {
            this.$dialog.open(IndexRole, {
                props: {
                    header: "Gestion des roles",
                    style: {
                        width: '50vw'
                    },
                    modal: true
                },
                data: objData
            });
        },
        getPermission(permission) {
            return this.$store.getters.permissions.includes(permission)
        },
    }
});
</script>