<template>
    <div class="btn-group page-nav " role="group" aria-label="nav">
        <router-link class="btn" :to="{name: 'camions'}" :class="{ 'active': (this.$route.name === 'camions') || (this.$route.name === 'camions/camion') || (this.$route.name === 'camions/yams')}" data-toggle="dropdown" aria-expanded="false" v-if="getPermission('voir-camions') === true">
            <i class="fa-solid fa-truck-moving"></i> Camions
        </router-link>
        <router-link class="btn" :to="{name: 'interventions'}" :class="{ 'active': this.$route.name === 'interventions' }" v-if="getPermission('voir-interventions') === true">
            <i class="fa-solid fa-screwdriver-wrench"></i> Interventions
        </router-link>
        <router-link class="btn" :to="{ name: 'proprietaires' }" :class="{ 'active': (this.$route.name === 'proprietaires') }" v-if="getPermission('voir-proprietaires') === true">
            <i class="fa-solid fa-user"></i> Propriétaires
        </router-link>
    </div>
</template>

<script >
import { defineComponent } from 'vue';

export default defineComponent ({
    methods: {
        getPermission(permission) {
            return this.$store.getters.permissions.includes(permission)
        },
    },
});
</script>