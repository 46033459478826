<template>
    <div>
        <button type="button" class="btn btn-light rounded-circle mr-2" @click="openShowModal()">
            <i class="far fa-bell text-xl"></i>
            <span v-if="this.$store.getters.notifications > 0" class="compteur badge badge-danger navbar-badge">
                {{ this.$store.getters.notifications }}
            </span>
        </button>
    </div>
</template>

<script>
import { defineComponent, defineAsyncComponent } from 'vue';
const IndexNotification = defineAsyncComponent(() => import('@/views/notifications/IndexNotification.vue'));

export default defineComponent ({
    name: 'App',
    data() {
        return {
            isLoading: true,
        }
    },
    mounted () {
        this.getNotifications()
    },
    methods: {
        openShowModal() {
            this.$dialog.open(IndexNotification, {
                props: {
                    header: "Notifications",
                    style: {
                        width: '70vw'
                    },
                    modal: true
                }
            });
        },

        async getNotifications() {
            await this.axios.get('/api/notifications?read_at=null&req_count').then((response) => {
                this.$store.commit('setNotifications', response.data)
                this.isLoading = false
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
    }
})
</script>

<style scoped>
#table_tr .btn:hover {
    background-color: #3142C5;
    color: #fff;
}

.compteur {
    margin: -5px;
}
</style>