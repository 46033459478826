<template>
    <div>
        <button class="btn btn-sm btn-primary mr-2" @click="imprimer()">
            <i class="fa-solid fa-download"></i> Télécharger une fiche
        </button>
        <div class="container-fluid" hidden>
            <div id="intervention">
                <Entete />
                <h2 class="text-center py-3">FICHE D'INTERVENTION</h2>
                <div class="row d-flex justify-content-between mb-4">
                    <div class="col-6 ">
                        <div class="">
                            <div>
                                <h5 class="badge badge-secondary"> Vehicule </h5>
                            </div>
                            <div class="card-body p-2">
                                <strong>Immatriculation: </strong><br>
                                <strong>Chauffeur: </strong><br>
                                <strong>Kilometrage: </strong><br>
                                <strong>Type : </strong> <br>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 ">
                        <div class="">
                            
                        </div>
                    </div>
                </div>
                <div>
                    <h5 class="badge badge-secondary">Information sur le Garage</h5>
                </div>
                <table class="table">
                    <thead>
                        <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Type intervention</th>
                        <th scope="col">Description de l'intervention</th>
                        <th scope="col">Garage</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td>&nbsp;</td>
                        <td>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox">
                                <label class="form-check-label" for="inlineCheckbox1">Entretien</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox">
                                <label class="form-check-label" for="inlineCheckbox2">Reparation</label>
                            </div>
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        </tr>
                        <tr>
                        <td>&nbsp;</td>
                        <td>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox">
                                <label class="form-check-label" for="inlineCheckbox1">Entretien</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox">
                                <label class="form-check-label" for="inlineCheckbox2">Reparation</label>
                            </div>
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        </tr>
                        <tr>
                        <td>&nbsp;</td>
                        <td>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox">
                                <label class="form-check-label" for="inlineCheckbox1">Entretien</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox">
                                <label class="form-check-label" for="inlineCheckbox2">Reparation</label>
                            </div>
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        </tr>
                        <tr>
                        <td>&nbsp;</td>
                        <td>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox">
                                <label class="form-check-label" for="inlineCheckbox1">Entretien</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox">
                                <label class="form-check-label" for="inlineCheckbox2">Reparation</label>
                            </div>
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox">
                                    <label class="form-check-label" for="inlineCheckbox1">Entretien</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox">
                                    <label class="form-check-label" for="inlineCheckbox2">Reparation</label>
                                </div>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            </tr>
                            <tr>
                            <td>&nbsp;</td>
                            <td>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox">
                                    <label class="form-check-label" for="inlineCheckbox1">Entretien</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox">
                                    <label class="form-check-label" for="inlineCheckbox2">Reparation</label>
                                </div>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            </tr>
                            <tr>
                            <td>&nbsp;</td>
                            <td>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox">
                                    <label class="form-check-label" for="inlineCheckbox1">Entretien</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox">
                                    <label class="form-check-label" for="inlineCheckbox2">Reparation</label>
                                </div>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            </tr>
                            <tr>
                            <td>&nbsp;</td>
                            <td>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox">
                                    <label class="form-check-label" for="inlineCheckbox1">Entretien</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox">
                                    <label class="form-check-label" for="inlineCheckbox2">Reparation</label>
                                </div>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            </tr>
                        
                            
                    </tbody> 
                </table><br><br><br>

                <table class="table">
                        <thead>
                            <tr>
                            <th scope="col">Date</th>
                            <th scope="col">Type intervention</th>
                            <th scope="col">Description de l'intervention</th>
                            <th scope="col">Garage</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td>&nbsp;</td>
                            <td>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox">
                                    <label class="form-check-label" for="inlineCheckbox1">Entretien</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox">
                                    <label class="form-check-label" for="inlineCheckbox2">Reparation</label>
                                </div>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            </tr>
                            <tr>
                            <td>&nbsp;</td>
                            <td>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox">
                                    <label class="form-check-label" for="inlineCheckbox1">Entretien</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox">
                                    <label class="form-check-label" for="inlineCheckbox2">Reparation</label>
                                </div>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            </tr>
                            <tr>
                            <td>&nbsp;</td>
                            <td>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox">
                                    <label class="form-check-label" for="inlineCheckbox1">Entretien</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox">
                                    <label class="form-check-label" for="inlineCheckbox2">Reparation</label>
                                </div>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            </tr>
                            <tr>
                            <td>&nbsp;</td>
                            <td>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox">
                                    <label class="form-check-label" for="inlineCheckbox1">Entretien</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="checkbox">
                                    <label class="form-check-label" for="inlineCheckbox2">Reparation</label>
                                </div>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox">
                                        <label class="form-check-label" for="inlineCheckbox1">Entretien</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox">
                                        <label class="form-check-label" for="inlineCheckbox2">Reparation</label>
                                    </div>
                                </td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                </tr>
                                <tr>
                                <td>&nbsp;</td>
                                <td>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox">
                                        <label class="form-check-label" for="inlineCheckbox1">Entretien</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox">
                                        <label class="form-check-label" for="inlineCheckbox2">Reparation</label>
                                    </div>
                                </td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                </tr>
                                <tr>
                                <td>&nbsp;</td>
                                <td>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox">
                                        <label class="form-check-label" for="inlineCheckbox1">Entretien</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox">
                                        <label class="form-check-label" for="inlineCheckbox2">Reparation</label>
                                    </div>
                                </td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                </tr>
                                <tr>
                                <td>&nbsp;</td>
                                <td>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox">
                                        <label class="form-check-label" for="inlineCheckbox1">Entretien</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox">
                                        <label class="form-check-label" for="inlineCheckbox2">Reparation</label>
                                    </div>
                                </td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                </tr>

                                <tr>
                                <td>&nbsp;</td>
                                <td>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox">
                                        <label class="form-check-label" for="inlineCheckbox1">Entretien</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox">
                                        <label class="form-check-label" for="inlineCheckbox2">Reparation</label>
                                    </div>
                                </td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                </tr>
                                <tr>
                                <td>&nbsp;</td>
                                <td>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox">
                                        <label class="form-check-label" for="inlineCheckbox1">Entretien</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox">
                                        <label class="form-check-label" for="inlineCheckbox2">Reparation</label>
                                    </div>
                                </td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                </tr>
                                <tr>
                                <td>&nbsp;</td>
                                <td>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox">
                                        <label class="form-check-label" for="inlineCheckbox1">Entretien</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox">
                                        <label class="form-check-label" for="inlineCheckbox2">Reparation</label>
                                    </div>
                                </td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                </tr>
                                <tr>
                                <td>&nbsp;</td>
                                <td>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox">
                                        <label class="form-check-label" for="inlineCheckbox1">Entretien</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input class="form-check-input" type="checkbox">
                                        <label class="form-check-label" for="inlineCheckbox2">Reparation</label>
                                    </div>
                                </td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox">
                                            <label class="form-check-label" for="inlineCheckbox1">Entretien</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox">
                                            <label class="form-check-label" for="inlineCheckbox2">Reparation</label>
                                        </div>
                                    </td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                    <td>&nbsp;</td>
                                    <td>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox">
                                            <label class="form-check-label" for="inlineCheckbox1">Entretien</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox">
                                            <label class="form-check-label" for="inlineCheckbox2">Reparation</label>
                                        </div>
                                    </td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    </tr>
                                    
                                    

                        
                            
                        </tbody> 
                    </table>
                <div class="py-5"></div>
               

                <div class="mx-5 d-flex justify-content-between mt-3 ">
                    <div class="">
                        <h6>Signature du chauffeur</h6>
                    </div>
                    <div class="">
                        <h6>Signature du garage</h6>
                    </div>
                </div>
                <hr>
            </div>
        </div>
    </div>
</template>
 
<script>
import Entete from '@/layouts/EnteteDesExport'
import html2pdf from "html2pdf.js";

export default {
    name: 'InterventionFiche',
    components: { Entete },
    data() {
        return {
            lignes: 4,
            ligne2: 14,
        }
    },
    methods: {
        imprimer() {
            // document.getElementById('footer-text').style.display = 'none'
            html2pdf().set({
                pagebreak: { mode: 'avoid-all' },
                jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
                margin: [0.5, 0.5],
                filename: "Fiche d'intervention YAM'S",
                html2canvas: { scale: 2 }
            }).from(document.getElementById("intervention"))
                .toPdf()
                .get('pdf')
                .then(function (pdf) {
                    var totalPages = pdf.internal.getNumberOfPages();
                    pdf.setFontSize(10);
                    pdf.setTextColor(150);
                    for (var i = 1; i <= totalPages; i++) {
                        pdf.setPage(i);
                        pdf.text(' S.A.R.L au capital de 1.000.000 F CFA | Oloumi - BP: 1073 Libreville | Tel: +241 (0) 1 74 78 91 - 062 13 71 78 |' + "\n" + 'RCCM: GA-LBV-01-2020-B13-00255 / NIF: 054889 C  |Email: infos@yamslogistics.com / Site web: yamslogistics.com', pdf.internal.pageSize.getWidth() - 0.50, pdf.internal.pageSize.getHeight() - 0.50, { align: 'right' });
                    }
                })
                .save();
        },
    }

}
</script>
<style scoped>
.details {
    font-size: 12px;
}
table, th, td {
  border:1px solid gray;
}
</style>