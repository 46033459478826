<template>
    <div class="header-contentys pt-3 d-flex align-items-center">
        <div class="mr-5">
            <h4 class="text-primary font-weight-bold">Propriétaires ({{ lines }})</h4>
        </div>
        <div class="mr-4">
            <NavParcAuto></NavParcAuto>
        </div>
        <div class="mr-4">
            <div class="btn-group page-nav " role="group" aria-label="Basic example">
                <a href="" class="btn " @click.prevent="changeAffichage('list')"
                    :class="{ 'active': affichage === 'list' }">
                    <i class="fa-solid fa-bars"></i>
                </a>
                <a href="" class="btn " @click.prevent="changeAffichage('cards')"
                    :class="{ 'active': affichage === 'cards' }">
                    <i class="fa-solid fa-border-all"></i>
                </a>
            </div>
        </div>
        <div class="px-1">
            <button class="btn-yams btn-active" type="button" @click="openCreateProprietaireModal()"
                v-if="getPermission('creer-proprietaires') === true">
                <i class="fa fa-plus"></i> Nouveau propriétaire
            </button>
        </div>
    </div>

    <hr>
    <div class="body-contentys">
        <DataTableBackend :key="key" url="/api/proprietaires" :columns="columns" :affichage="affichage" :idParams="this.$route.params.id" :table="table" backendFilter selection inCard
        v-on:set-total-records="updateLines">
            <template #action_group_btns=slotProps>
                <button type="button" class="btn btn-sm btn-danger mr-2" @click="deleteGroup(slotProps.data)">
                    <i class="fas fa-trash-alt"></i> Supprimer
                    <span v-if="isLoading == true" class="spinner-border spinner-border-sm" role="status"
                        aria-hidden="true"></span>
                </button>
            </template>
            <template #action_btns=slotProps>
                <button class="btn btn-sm btn-outline-dark" @click="openShowModal(slotProps.data)">
                    <i class="fa-solid fa-eye"></i>
                </button>
            </template>
        </DataTableBackend>
    </div>
</template>
    
<script>
import $ from "jquery";
import { defineComponent, defineAsyncComponent } from 'vue';
import NavParcAuto from '../NavParcAuto.vue';
const ShowProprietaire = defineAsyncComponent(() => import('./ShowProprietaire.vue'));
const CreateProprietaireVue = defineAsyncComponent(() => import('./CreateProprietaire.vue'));
const EditProprietaireVue = defineAsyncComponent(() => import('./EditProprietaire.vue'));

export default defineComponent({
    components: { NavParcAuto },
    data() {
        return {
            lines: 0,
            key:1,
            isLoading: false,
            table: 'proprietaires',
            affichage: "list",
            columns: [
                {
                    key: 'sigle',
                    label: 'Code',
                    exportable: true
                },
                {
                    key: 'nom',
                    label: 'Nom',
                    exportable: true
                },
                {
                    key: 'email',
                    label: 'Email',
                    exportable: true
                },
                {
                    key: 'telephone1',
                    label: 'Telephone',
                    exportable: true
                },
                {
                    key: 'adresse',
                    label: 'Adresse',
                    exportable: true
                },
                {
                    key: 'bp',
                    label: 'BP',
                    exportable: true
                },
                {
                    key: 'nb_camion',
                    label: 'Nb Camions',
                    exportable: true
                }
            ]
        }
    },

    methods: {
        getPermission(permission) {
            return this.$store.getters.permissions.includes(permission)
        },
        changeAffichage(type) {
            this.affichage = type;
            this.key++
        },
        openShowModal(objData) {
            this.$dialog.open(ShowProprietaire, {
                props: {
                    header: "Propriétaire ID" + objData.id,
                    style: {
                        width: "50vw"
                    },
                    modal: true
                },
                data: objData
            });
        },
        openCreateProprietaireModal() {
            this.$dialog.open(CreateProprietaireVue, {
                props: {
                    header: "Nouveau propriétaire",
                    style: {
                        width: '50vw'
                    },
                    modal: true
                }
            });
        },
        openEditProprietaireModal(objData) {
            this.$dialog.open(EditProprietaireVue, {
                props: {
                    style: {
                        width: '50vw'
                    },
                    modal: true
                },
                data: objData
            });
        },
        deleteLine(id) {
            if (!window.confirm("Etes vous sur de vouloir supprimer ?")) {
                return
            }
            this.isLoading = true
            this.axios.post('/api/users/' + id + '/destroy').then(response => {
                this.isLoading = false
                if (response.data.success === true) {
                    $('#refresh' + this.table).click()
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Supprimé',
                        detail: response.data.message,
                        life: 3000
                    });
                }
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        deleteGroup(selection) {
            if (!window.confirm("Etes vous sur de vouloir supprimer ?")) {
                return
            }

            this.isLoading = true
            let selectedLines = []
            for (let index = 0; index < selection.length; index++) {
                const element = selection[index];
                selectedLines.push(element.id)
            }

            this.axios.post('/api/users/destroy-group', { selected_lines: selectedLines }).then((response) => {
                this.isLoading = false
                if (response.data.success === true) {
                    $('#refresh' + this.table).click()
                    for (let index = 0; index < response.data.message.length; index++) {
                        const element = response.data.message[index];
                        this.$toast.add({
                            severity: element.severity,
                            detail: element.value,
                            life: parseInt(5000 * (index + 1))
                        });
                    }
                }
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        updateLines(nb_lines) {
            this.lines = nb_lines
        }
    }
})
</script>