
<template>
    <div class="card">
        <div class="card-header">
            Recouvrements en attente et reglés
        </div>
        <div class="card-body p-0">
            <ProgressBar v-if="isLoading === true" mode="indeterminate" style="height: 6px"></ProgressBar>
            <ChartJS type="doughnut" :data="chartData" :options="chartOptions" class="h-30rem" />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isLoading: false,
            chartData: [],
            chartOptions: null,
        };
    },
    mounted() {
        this.chartOptions = this.setChartOptions();
        this.setChartData()
    },
    methods: {
        setChartData() {
            this.isLoading = true
            this.axios.get('/api/courses?payes_and_impayes=1').then((response) => {
                let labels = [], values = []
                for (let index = 0; index < response.data.length; index++) {
                    const element = response.data[index];
                    labels.push(element.label)
                    values.push(element.value)
                }

                this.chartData = {
                    labels: labels,
                    datasets: [
                        {
                            data: values,
                            backgroundColor: [
                                '#0C266C', 
                                '#0C966C', 
                            ],
                            hoverBackgroundColor: '#995050',
                            borderWidth: 1
                        }
                    ]
                };

                this.isLoading = false
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            });
        },
        setChartOptions() {
            const documentStyle = getComputedStyle(document.documentElement);
            const textColor = documentStyle.getPropertyValue('--text-color');

            return {
                plugins: {
                    legend: {
                        labels: {
                            cutout: '60%',
                            color: textColor
                        }
                    }
                }
            };
        },
    }
};
</script>
